/* Login.css */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Use min-height to ensure it covers the entire viewport */
  background: #7ac1eb; 
  padding: 20px; /* Add some padding for smaller screens */
  box-sizing: border-box; /* Include padding in width/height calculations */
}

.login-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  padding: 3rem;
  width: 100%;
  max-width: 500px; /* Limit the maximum width */
  text-align: center;
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.login-card:hover {
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2); /* Enhance hover effect */
  transform: translateY(-3px); /* Slight lift on hover */
}

.login-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: 600; /* Slightly bolder */
  letter-spacing: 0.5px; /* Improve readability */
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Stretch items to full width */
}

.form-groups {
  margin-bottom: 1.5rem;
  text-align: left;
}

.form-groups label {
  display: block;
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-groups input {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.form-groups input:focus {
  border-color: #764ba2;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(118, 75, 162, 0.25);
}

.login-button {
  background: #3c8dbc; 
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.login-button:hover {
  background:#1b86c4; 
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.error-message {
  color: #d8000c;
  background-color: #ffbaba;
  border: 1px solid #d8000c;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .login-card {
    padding: 2rem;
  }

  .login-title {
    font-size: 2rem;
  }

  .form-groups label {
    font-size: 1rem;
  }

  .form-groups input {
    padding: 0.8rem;
    font-size: 0.9rem;
  }

  .login-button {
    padding: 0.8rem 1.5rem;
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .login-card {
    padding: 1.5rem;
  }

  .login-title {
    font-size: 1.75rem;
  }

  .form-groups {
    margin-bottom: 1rem;
  }

  .form-groups label {
    font-size: 0.9rem;
  }

  .form-groups input {
    padding: 0.7rem;
    font-size: 0.85rem;
  }

  .login-button {
    padding: 0.7rem 1.4rem;
    font-size: 1rem;
  }
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: #3c8dbc;   
  list-style: none;
  padding: 0.5rem 0;
  margin: 0;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  display: none; /* Hidden by default */
  z-index: 101; /* Above other elements */
  min-width: 200px;
}
.dropdown-item:hover {
  background:#3c8dbc;
}