/* Video gallery container */
.video-gallery-drugs-video-gallery-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f8f8f8;
}

/* Video gallery title */
.video-gallery-drugs-video-gallery-title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

/* Grid container */
.video-gallery-drugs-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

/* Grid item */
.video-gallery-drugs-grid-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease;
}

.video-gallery-drugs-grid-item:hover {
    transform: scale(1.05);
}

/* Grid thumbnail */
.video-gallery-drugs-grid-thumbnail {
    width: 100%;
    height: auto;
    display: block;
}

/* Modal (overlay) */
.video-gallery-drugs-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal content */
.video-gallery-drugs-modal-content {
    position: relative;
    max-width: 80%;
    max-height: 80%;
    border-radius: 8px;
    overflow: hidden;
}

/* Modal video */
.video-gallery-drugs-modal-video {
    width: 100%;
    height: auto;
    display: block;
}

/* Close button */
.video-gallery-drugs-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    font-size: 2em;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .video-gallery-drugs-grid-container {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 10px;
    }

    .video-gallery-drugs-modal-content {
        max-width: 95%;
        max-height: 95%;
    }
}