/* AddNewspaper.css - Responsive Styles */

.newspaper-form {
    margin-bottom: 20px;
    padding: 15px;
    text-align: justify;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-sizing: border-box; /* Important: Include padding and border in element's total width and height */
}

.newspaper-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.newspaper-form input[type="text"],
.newspaper-form input[type="file"],
.newspaper-form input[type="date"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
}

.newspaper-form button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%; /* Full width on smaller screens */
}

.newspaper-form button:hover {
    background-color: #45a049;
}

.error {
    color: red;
    margin-top: 10px;
}

/* Media Queries for Responsiveness */

/* Smaller screens (e.g., phones) */
@media (max-width: 576px) {
    .newspaper-form {
        padding: 10px;
    }

    .newspaper-form label {
        font-size: 14px;
        margin-bottom: 3px;
    }

    .newspaper-form input[type="text"],
    .newspaper-form input[type="file"],
    .newspaper-form input[type="date"] {
        font-size: 12px;
        padding: 6px;
        margin-bottom: 8px;
    }

    .newspaper-form button {
        font-size: 14px;
        padding: 8px 12px;
    }
}

/* Medium screens (e.g., tablets) */
@media (min-width: 577px) and (max-width: 992px) {
    .newspaper-form {
        padding: 12px;
    }

    .newspaper-form label {
        font-size: 15px;
    }

    .newspaper-form input[type="text"],
    .newspaper-form input[type="file"],
    .newspaper-form input[type="date"] {
        font-size: 13px;
    }
}

/* Larger screens (desktops) - Adjust as needed */
@media (min-width: 993px) {
    /* You can add specific styles for larger screens here if needed */
    /* For example, you might want to limit the width of the form */
    /*.newspaper-form {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }*/
    .newspaper-form button{
      width: auto;
    }

}