/* form {
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 1120px;
  }
   */
  h1 {
    text-align: center;
    color: #333;
  }
  
  label {
    display: block;
    margin-top: 15px;
    color: #555;
  }
  
  input, select {
    width: 98%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  input[type="checkbox"] {
    width: auto;
    margin-right: 10px;
  }
  

  
  /* Responsive Design */
  @media (max-width: 768px) {
    form {
      padding: 20px;
    }
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 24px;
    }
    input, select, button {
      font-size: 14px;
    }
  }
  /* src/Components/Categories/ManageCategories.css */

/* --- Styles for Main Container and Table (from previous ManageCategories.css) --- */
.manage-categories-container {
  padding: 20px;
  max-width: 1200px;
  margin: 20px auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.manage-categories-container h1 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  font-weight: 600;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.categories-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
}

.categories-table th,
.categories-table td {
  border: 1px solid #ddd;
  padding: 12px 15px;
  text-align: left;
  vertical-align: middle;
}

.categories-table th {
  background-color: #f2f2f2;
  font-weight: 600;
  color: #555;
  white-space: nowrap;
}

.categories-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.categories-table tbody tr:hover {
  background-color: #f1f1f1;
}

.categories-table td[title] {
  cursor: help;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-cell {
  text-align: center;
}

.action-cell {
  text-align: center;
  white-space: nowrap;
}

.action-cell .btn {
  padding: 6px 12px;
  margin: 0 4px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease, opacity 0.2s ease;
}
.action-cell .btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn-edit {
  background-color: #ffc107;
  color: #333;
}
.btn-edit:hover:not(:disabled) {
  background-color: #e0a800;
}

.btn-delete {
  background-color: #dc3545;
  color: white;
}
.btn-delete:hover:not(:disabled) {
  background-color: #c82333;
}

.no-data-cell {
  text-align: center;
  font-style: italic;
  color: #888;
  padding: 20px;
}

/* Alert / Message styling */
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.loading-indicator {
  text-align: center;
  padding: 20px;
  color: #555;
  font-style: italic;
}

/* --- Styles for Edit Modal (from previous EditCategoryModal.css) --- */

.edit-category-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 15px;
  backdrop-filter: blur(4px);
  animation: fadeInOverlay 0.3s ease;
}

@keyframes fadeInOverlay { from { opacity: 0; } to { opacity: 1; } }

.edit-category-modal-content {
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 5px 20px rgba(0,0,0,0.15);
  position: relative;
  animation: scaleUpModal 0.3s ease-out;
}

@keyframes scaleUpModal {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}

.edit-category-modal-content h2 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 25px;
  color: #333;
  font-weight: 600;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.8rem;
  line-height: 1;
  color: #aaa;
  cursor: pointer;
  padding: 5px;
  transition: color 0.2s ease, transform 0.2s ease;
}
.modal-close-btn:hover {
  color: #dc3545;
  transform: rotate(90deg);
}

.edit-category-modal-content form {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.edit-category-modal-content label {
  font-weight: 500;
  color: #555;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.edit-category-modal-content input[type="text"],
.edit-category-modal-content textarea,
.edit-category-modal-content select {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}
.edit-category-modal-content input[type="text"]:focus,
.edit-category-modal-content textarea:focus,
.edit-category-modal-content select:focus {
  border-color: #ffc107;
  box-shadow: 0 0 0 2px rgba(255, 193, 7, 0.2);
  outline: none;
}

.edit-category-modal-content textarea {
  resize: vertical;
  min-height: 80px;
}

.edit-category-modal-content label.checkbox-label {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-weight: normal;
  cursor: pointer;
}
.edit-category-modal-content input[type="checkbox"] {
  width: auto;
  margin-right: 5px;
  accent-color: #ffc107;
  height: 1.1em;
  width: 1.1em;
  cursor: pointer;
}

/* Checkbox group styling */
.form-group .checkbox-group-title {
  margin-bottom: 10px;
  font-weight: 500;
  color: #555;
}
.form-group .checkbox-group {
  display: flex;
  gap: 20px;
  padding-left: 5px;
}


/* Modal Actions (Buttons) */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.modal-actions .btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition: background-color 0.2s ease, opacity 0.2s ease;
}
.modal-actions .btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.btn-cancel {
  background-color: #f8f9fa;
  color: #555;
  border: 1px solid #ccc;
}
.btn-cancel:hover:not(:disabled) {
  background-color: #e2e6ea;
}

.btn-save {
  background-color: #28a745;
  color: white;
}
.btn-save:hover:not(:disabled) {
  background-color: #218838;
}

/* Error message inside modal */
.alert.modal-error {
  margin-bottom: 15px; /* Space below error */
  margin-top: -10px; /* Pull up slightly */
  text-align: left; /* Align text left */
  padding: 10px 15px;
  font-size: 0.9rem;
}


/* --- Responsive table adjustments (Keep from previous) --- */
@media (max-width: 768px) {
  .categories-table th,
  .categories-table td {
      padding: 10px 8px;
      font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
   .categories-table th:nth-child(1),
   .categories-table td:nth-child(1),
   .categories-table th:nth-child(5),
   .categories-table td:nth-child(5),
   .categories-table th:nth-child(6),
   .categories-table td:nth-child(6) {
      display: none;
  }
  .action-cell .btn {
      padding: 5px 8px;
      font-size: 0.8rem;
      display: inline-block;
      margin-bottom: 5px;
  }
   .action-cell {
       white-space: normal;
   }
   .edit-category-modal-content {
       padding: 20px;
   }
   .edit-category-modal-content h2 { font-size: 1.3rem; margin-bottom: 20px;}
   .modal-actions { flex-direction: column; gap: 8px;}
   .modal-actions .btn { width: 100%; } /* Stack buttons */
}
