.captcha-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
  }
  
  .captcha-canvas {
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #fefefe;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
  }
  
  .captcha-refresh {
    padding: 8px 12px;
    font-size: 18px;
    border: none;
    background: #007bff;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .captcha-refresh:hover {
    background: #0056b3;
  }
  
  .captcha-input {
    flex: 1;
    min-width: 150px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
  }
  