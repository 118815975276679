/* FullNewsPage.css */

/* ------- General Styles ------- */
.full-news-page-container {
    font-family: 'Poppins', sans-serif; /* A modern, clean font */
    background-color: #f9f9f9; /* Lighter background */
    color: #333;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure full viewport height */
    box-sizing: border-box; /* Important: Include padding and border in the element's total width and height */
}

/* ------- Header Styles ------- */
.news-header {
    background-color: #ffffff;
    color: #333;
    padding: 2rem 1rem; /* Adjusted padding for smaller screens */
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Softer shadow */
    border-bottom: 1px solid #eee; /* Subtle border */
    box-sizing: border-box;
}

.news-header h1 {
    margin: 0;
    font-size: 2rem; /* Adjusted font size for smaller screens */
    font-weight: 700; /* Bold font weight */
    letter-spacing: -0.5px; /* Slightly tighter letter spacing */
    color: #2c3e50; /* Darker color */
}

.news-header .header-subtitle {
    font-size: 1rem; /* Adjusted font size for smaller screens */
    color: #777;
    margin-top: 0.5rem;
}

/* ------- News Grid Styles ------- */
.news-grid {
    display: grid;
    /*  Adjusted minmax to be more responsive */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem; /* Adjusted gap for smaller screens */
    padding: 1.5rem 1rem; /* Adjusted padding for smaller screens */
    flex: 1;
    box-sizing: border-box;
}

/* ------- News Card Styles ------- */
.news-card {
    background-color: #fff;
    border-radius: 10px; /* Adjusted for smaller screens */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08); /* Softer shadow */
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    overflow: hidden;
    box-sizing: border-box;
}

.news-card:hover {
    transform: translateY(-5px); /* Reduced lift on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Reduced shadow on hover */
}

/* ------- Image Styles ------- */
.news-card-image-container {
    height: 150px; /* Adjusted image height for smaller screens */
    overflow: hidden;
    box-sizing: border-box;
}

.news-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the container without distortion */
    display: block; /* Remove extra space below the image */
    transition: transform 0.3s ease; /* Zoom effect on hover */
    box-sizing: border-box;
}

.news-card:hover .news-card-image {
    transform: scale(1.05); /* Reduced zoom on hover */
}

/* ------- Content Styles ------- */
.news-card-content {
    padding: 1.5rem; /* Adjusted padding for smaller screens */
    box-sizing: border-box;
}

.news-card-title {
    font-size: 1.2rem; /* Adjusted font size for smaller screens */
    margin-bottom: 0.75rem;
    color: #2c3e50;
    font-weight: 600; /* Semi-bold font weight */
}

.news-card-date {
    font-size: 0.8rem; /* Adjusted font size for smaller screens */
    color: #777;
    margin-bottom: 1rem;
    display: block; /* Display as block to take full width */
    box-sizing: border-box;
}

.news-card-summary {
    font-size: 1rem; /* Adjusted font size for smaller screens */
    line-height: 1.4;
    color: #555;
    box-sizing: border-box;
}

/* ------- Link Styles ------- */
.news-card-link {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.75rem 1.5rem; /* Adjusted padding for smaller screens */
    background-color: #3498db; /* A brighter, more engaging blue */
    color: #fff;
    text-decoration: none;
    border-radius: 5px; /* Adjusted for smaller screens */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition */
    font-weight: 500;
    font-size: 0.9rem; /* Adjusted font size for smaller screens */
    box-sizing: border-box;
}

.news-card-link:hover {
    background-color: #2980b9; /* Darker shade on hover */
    transform: translateY(-2px); /* Slight lift on hover */
}

/* ------- Footer Styles ------- */
.news-footer {
    background-color: #2c3e50;
    color: #fff;
    text-align: center;
    padding: 1rem; /* Adjusted padding for smaller screens */
    font-size: 0.8rem; /* Adjusted font size for smaller screens */
    box-sizing: border-box;
}

/* ------- Loading and Error Styles ------- */
.loading-spinner, .error-message {
    text-align: center;
    padding: 2rem; /* Adjusted padding for smaller screens */
    font-size: 1rem; /* Adjusted font size for smaller screens */
    color: #777;
    box-sizing: border-box;
}

/* ------- Animation ------- */
.animated-card {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ------- Media Queries ------- */

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  .news-header h1 {
    font-size: 1.75rem;
  }

  .news-header .header-subtitle {
    font-size: 0.9rem;
  }

  .news-grid {
    grid-template-columns: 1fr; /* Single column on small screens */
    padding: 1rem;
    gap: 1rem;
  }

  .news-card-title {
    font-size: 1.1rem;
  }

  .news-card-content {
    padding: 1rem;
  }

  .news-card-image-container {
    height: 120px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .news-grid {
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .news-header h1 {
        font-size: 2.25rem;
    }

    .news-grid {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
    .news-card-image-container {
      height: 180px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .news-grid {
        grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .news-grid {
        grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    }
    .news-header h1 {
      font-size: 2.75rem;
    }
}