/* --- Variables --- */
:root {
  /* Core Palette */
  --news-color-primary: #4a69bd;
  --news-color-secondary: #6a89cc;
  --news-color-accent: #f6b93b;
  --news-text-dark: #2c3e50;
  --news-text-medium: #576574;
  --news-text-light: #8395a7;
  --news-bg-main: #ffffff;
  --news-bg-alt: #f7f8fc;
  --news-border-color: #e4e7ed;

  /* Typography */
  --news-font-heading: 'Poppins', sans-serif;
  --news-font-body: 'Source Sans Pro', sans-serif;
  --news-font-meta: 'Inter', sans-serif;

  /* Sizing & Spacing */
  --news-border-radius: 6px;
  --news-spacing-unit: 1rem;
  --news-container-max-width: 1200px;
  --news-sidebar-width: 320px;
  --news-content-gap: calc(var(--news-spacing-unit) * 2); /* Reduced gap slightly */

  /* Effects */
  --news-shadow-soft: 0 4px 15px rgba(0, 0, 0, 0.06);
  --news-shadow-medium: 0 6px 20px rgba(44, 62, 80, 0.1);
  --news-transition-speed: 0.3s ease;
}

/* --- Base & General --- */
body {
  font-family: var(--news-font-body);
  color: var(--news-text-medium);
  background-color: var(--news-bg-alt);
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0; /* Ensure no default body margin */
}

*, *::before, *::after {
    box-sizing: border-box; /* Better box model */
}

.news-detail-page-description {
  padding: calc(var(--news-spacing-unit) * 2) 0; /* Reduced top/bottom padding slightly */
  overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Container */
.container-description {
  max-width: var(--news-container-max-width);
  margin: 0 auto;
  padding: 0 var(--news-spacing-unit);
}

/* Row Layout (Flexbox based) */
.row-description {
  display: flex;
  flex-direction: column; /* Stack vertically by default (mobile) */
  gap: var(--news-content-gap); /* Apply gap in column direction too */
}

/* --- Main Content Area --- */
.news-detail-main-content-description {
  width: 100%; /* Take full width in column layout */
  min-width: 0; /* Important for flex children */
  background-color: var(--news-bg-main);
  border-radius: var(--news-border-radius);
  box-shadow: var(--news-shadow-soft);
  overflow: hidden; /* Contain children */
}

/* --- Hero Section Styling --- */
.news-detail-img-description {
  margin-bottom: 0; /* Remove margin if header provides padding */
  width: 100%;
  line-height: 0; /* Prevent extra space below image */
}
.news-detail-img-description img {
  display: block;
  width: 100%;
  height: auto; /* Allow height to adjust based on width */
  /* Removed max-height to prevent cutting off on mobile */
  object-fit: cover; /* Cover the area */
  border-radius: 0;
}

.news-detail-header-description {
  padding: calc(var(--news-spacing-unit) * 1.5) calc(var(--news-spacing-unit) * 1.5); /* Adjusted padding */
  margin-bottom: calc(var(--news-spacing-unit) * 1.5); /* Adjusted margin */
  border-bottom: 1px solid var(--news-border-color);
  position: relative;
}

.news-detail-header-description .meta-info-description {
  margin-bottom: calc(var(--news-spacing-unit) * 0.75); /* Adjusted margin */
  display: flex;
  flex-wrap: wrap; /* Allow meta items to wrap */
  align-items: center;
  gap: calc(var(--news-spacing-unit) * 0.75); /* Adjusted gap */
  font-family: var(--news-font-meta);
  font-size: 0.85rem; /* Slightly smaller meta text */
  color: var(--news-text-light);
}

.news-detail-header-description .news-detail-category-description {
  display: inline-block;
  background-color: var(--news-color-primary);
  color: var(--news-bg-main);
  padding: 0.3em 0.8em;
  border-radius: 50px;
  font-weight: 600;
  font-size: 0.75rem; /* Slightly smaller category */
  text-transform: uppercase;
  letter-spacing: 0.8px;
  transition: background-color var(--news-transition-speed);
}
.news-detail-header-description .news-detail-category-description:hover {
  background-color: color-mix(in srgb, var(--news-color-primary) 92%, black); /* Use color-mix for darkening */
}

.news-detail-header-description .news-detail-date-description {
    white-space: nowrap; /* Prevent date wrapping */
}
.news-detail-header-description .news-detail-date-description::before {
  content: '•';
  margin-right: calc(var(--news-spacing-unit) * 0.5); /* Adjusted spacing */
  color: var(--news-text-light);
}

.news-detail-header-description h1 {
  font-family: var(--news-font-heading);
  font-size: clamp(1.8rem, 5vw, 2.8rem); /* Adjusted clamp for title */
  margin-top: calc(var(--news-spacing-unit) * 0.5); /* Add some space above title */
  margin-bottom: calc(var(--news-spacing-unit) * 1); /* Space below title */
  line-height: 1.25;
  color: var(--news-text-dark);
  font-weight: 700;
  word-break: break-word; /* Prevent long titles overflowing */
}

/* --- Speech Button --- */
.speech-toggle-button {
  background: none;
  border: 1px solid var(--news-border-color);
  padding: 6px 12px;
  /* margin-left: 15px; Removed margin-left, let it flow */
  margin-top: calc(var(--news-spacing-unit) * 0.5); /* Space above button */
  cursor: pointer;
  color: var(--news-text-medium);
  vertical-align: middle;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
  font-size: 0.9rem;
}
.speech-toggle-button:hover:not(:disabled) {
  background-color: var(--news-bg-alt);
  border-color: var(--news-color-secondary);
}
.speech-toggle-button.speaking {
   background-color: color-mix(in srgb, var(--news-color-primary) 15%, transparent); /* Light primary background */
   border-color: var(--news-color-primary);
   color: var(--news-color-primary);
}
.speech-toggle-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}
.speech-toggle-button:focus {
  outline: 2px solid var(--news-color-secondary);
  outline-offset: 2px;
}

/* --- Article Body --- */
.news-detail-body-description {
  padding: 0 calc(var(--news-spacing-unit) * 1.5); /* Matched header padding */
  font-size: 1.05rem; /* Adjusted body font size */
  line-height: 1.8;
  color: var(--news-text-dark);
  word-wrap: break-word; /* Ensure long words wrap */
}

.news-detail-body-description p {
  margin-bottom: calc(var(--news-spacing-unit) * 1.5);
}

.news-detail-body-description a {
  color: var(--news-color-primary);
  text-decoration: underline; /* Standard underline for links */
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
  font-weight: 600;
  background-image: none; /* Removed background highlight */
  transition: color var(--news-transition-speed);
}
.news-detail-body-description a:hover {
  color: color-mix(in srgb, var(--news-color-primary) 80%, black);
  text-decoration-thickness: 2px;
}

.news-detail-body-description blockquote {
    margin: calc(var(--news-spacing-unit) * 1.5) 0; /* Adjusted margin */
    padding: calc(var(--news-spacing-unit) * 1) calc(var(--news-spacing-unit) * 1.5);
    padding-left: calc(var(--news-spacing-unit) * 1.5); /* Adjusted padding */
    border-left: 4px solid var(--news-color-primary);
    background-color: var(--news-bg-alt);
    font-style: italic;
    font-size: 1rem; /* Adjusted quote size */
    color: var(--news-text-medium);
    border-radius: 0 var(--news-border-radius) var(--news-border-radius) 0;
}
.news-detail-body-description blockquote p:last-child {
    margin-bottom: 0;
}

/* --- Social Share --- */
.social-share-description {
  margin: calc(var(--news-spacing-unit) * 2) calc(var(--news-spacing-unit) * 1.5) 0; /* Matched padding */
  padding: calc(var(--news-spacing-unit) * 1.5) 0;
  border-top: 1px solid var(--news-border-color);
}

.social-share-description .share-prompt-description {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping */
  align-items: center;
  gap: var(--news-spacing-unit);
}

.social-share-description strong {
  font-family: var(--news-font-meta);
  font-size: 0.9rem; /* Adjusted size */
  color: var(--news-text-dark);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-right: calc(var(--news-spacing-unit) * 0.5); /* Space after "Share:" */
}

.social-share-description ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap; /* Allow icons to wrap */
  gap: calc(var(--news-spacing-unit) * 1);
}

.social-share-description li a {
  display: block;
  color: var(--news-text-medium); /* Set icon color */
  opacity: 0.8;
  transition: opacity var(--news-transition-speed), transform var(--news-transition-speed), color var(--news-transition-speed);
}
.social-share-description li a:hover {
  opacity: 1;
  transform: translateY(-2px);
  color: var(--news-color-primary); /* Highlight color on hover */
}
.social-share-description li a svg {
  width: 20px; /* Adjusted icon size */
  height: auto;
  display: block;
  fill: currentColor;
}

/* --- Comment Section --- */
.comment-section-description {
  padding: calc(var(--news-spacing-unit) * 2) calc(var(--news-spacing-unit) * 1.5); /* Matched padding */
  margin-top: calc(var(--news-spacing-unit) * 2);
  border-top: 1px solid var(--news-border-color);
}

.comment-form-title-description {
  font-family: var(--news-font-heading);
  font-size: 1.5rem; /* Adjusted title size */
  margin-bottom: calc(var(--news-spacing-unit) * 1.5); /* Adjusted margin */
  color: var(--news-text-dark);
  font-weight: 600;
}

.comment-form-description .form-group-description {
  margin-bottom: calc(var(--news-spacing-unit) * 1.2);
}

.comment-form-description .form-row-description {
  display: flex;
  flex-wrap: wrap; /* Keep wrapping for inputs */
  gap: calc(var(--news-spacing-unit) * 1.5);
}
.comment-form-description .form-group-half-description {
  flex: 1 1 calc(50% - var(--news-spacing-unit) * 0.75);
  min-width: 180px; /* Prevent inputs becoming too small */
}

.form-control-description {
  display: block;
  width: 100%;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  font-family: var(--news-font-body);
  line-height: 1.6;
  color: var(--news-text-dark);
  background-color: var(--news-bg-alt);
  background-clip: padding-box;
  border: 1px solid var(--news-border-color);
  border-radius: var(--news-border-radius);
  transition: border-color var(--news-transition-speed), box-shadow var(--news-transition-speed);
}
.form-control-description:focus {
  border-color: var(--news-color-secondary);
  outline: 0;
  box-shadow: 0 0 0 3px color-mix(in srgb, var(--news-color-primary) 15%, transparent);
  background-color: var(--news-bg-main);
}
.form-control-description::placeholder {
  color: var(--news-text-light);
  opacity: 1;
}

textarea.form-control-description {
  min-height: 120px; /* Adjusted textarea height */
  resize: vertical;
}

/* Button Styling */
.btn-description {
  display: inline-block;
  font-family: var(--news-font-meta);
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.7rem 1.5rem; /* Adjusted button padding */
  font-size: 0.9rem; /* Adjusted button font size */
  line-height: 1.5;
  border-radius: 50px;
  transition: all var(--news-transition-speed);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.btn-description:focus {
   outline: 0;
   box-shadow: 0 0 0 3px color-mix(in srgb, var(--news-color-primary) 25%, transparent);
}

.btn-primary-description { /* Note: Chained class selector */
  color: var(--news-bg-main);
  background: linear-gradient(45deg, var(--news-color-secondary), var(--news-color-primary));
  border-color: var(--news-color-primary);
  box-shadow: 0 4px 10px color-mix(in srgb, var(--news-color-primary) 20%, transparent);
}
.btn-primary-description:hover {
  color: var(--news-bg-main);
  background: linear-gradient(45deg, color-mix(in srgb, var(--news-color-secondary) 95%, black), color-mix(in srgb, var(--news-color-primary) 95%, black));
  box-shadow: 0 6px 15px color-mix(in srgb, var(--news-color-primary) 30%, transparent);
  transform: translateY(-2px);
}

/* --- Sidebar --- */
.news-detail-sidebar-description {
  width: 100%; /* Already set for column layout */
}

.sidebar-widget-description {
  background-color: var(--news-bg-main);
  padding: calc(var(--news-spacing-unit) * 1.5); /* Adjusted widget padding */
  margin-bottom: var(--news-content-gap); /* Use gap variable */
  border-radius: var(--news-border-radius);
  box-shadow: var(--news-shadow-soft);
  border: 1px solid var(--news-border-color);
}
/* Remove margin from last widget */
.news-detail-sidebar-description > .sidebar-widget-description:last-child {
    margin-bottom: 0;
}


.widget-title-description {
  font-family: var(--news-font-heading);
  font-size: 1.2rem; /* Adjusted widget title size */
  margin-bottom: calc(var(--news-spacing-unit) * 1.2); /* Adjusted margin */
  padding-bottom: calc(var(--news-spacing-unit) * 0.5); /* Adjusted padding */
  border-bottom: 2px solid var(--news-color-primary);
  display: inline-block;
  color: var(--news-text-dark);
  font-weight: 600;
}

/* Popular News Widget Specifics */
.popular-news-widget-description .widget-content-description {
  display: flex;
  flex-direction: column;
  gap: calc(var(--news-spacing-unit) * 1.2); /* Adjusted gap */
}

.popular-news-item-description {
  display: flex;
  gap: var(--news-spacing-unit);
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid var(--news-border-color);
  transition: background-color var(--news-transition-speed);
  border-radius: calc(var(--news-border-radius) * 0.5);
  margin: 0; /* Removed negative margin */
  padding: calc(var(--news-spacing-unit) * 0.75) 0; /* Use padding instead of negative margin */
}
.popular-news-item-description:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.popular-news-item-description:hover {
    background-color: var(--news-bg-alt);
    /* Add padding on hover if needed to visualise background */
    /* padding-left: 5px; padding-right: 5px; */
}

.popular-news-item-image-description {
  flex-shrink: 0;
  width: 70px; /* Adjusted image size */
  height: 55px; /* Adjusted image size */
  overflow: hidden;
  border-radius: calc(var(--news-border-radius) * 0.8);
}

.popular-news-item-image-description img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.popular-news-item-content-description {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popular-news-item-title-description {
  font-family: var(--news-font-body);
  font-size: 0.9rem; /* Adjusted title size */
  font-weight: 600;
  line-height: 1.4;
  color: var(--news-text-dark);
  margin: 0 0 calc(var(--news-spacing-unit) * 0.25) 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color var(--news-transition-speed); /* Add transition */
}
.popular-news-item-description:hover .popular-news-item-title-description {
    color: var(--news-color-primary);
}


.popular-news-item-date-description {
  font-family: var(--news-font-meta);
  font-size: 0.75rem; /* Adjusted date size */
  color: var(--news-text-light);
  display: block;
}

/* Placeholder/Loading Styles within Widget */
.widget-loading-description,
.widget-placeholder-description {
    font-family: var(--news-font-meta);
    font-size: 0.9rem;
    color: var(--news-text-light);
    padding: var(--news-spacing-unit) 0;
    text-align: center;
}


/* Ad Widget */
.ad-widget-description .widget-content-description a { display: block; }
.ad-widget-description img { max-width: 100%; height: auto; display: block; border-radius: calc(var(--news-border-radius) * 0.8); }


/* --- Responsiveness --- */

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .news-detail-page-description {
      padding: calc(var(--news-spacing-unit) * 3) 0; /* Restore padding */
  }
  .row-description {
    flex-direction: row; /* Change to horizontal layout */
    /* gap is already applied */
  }

  .news-detail-main-content-description {
      flex: 1; /* Re-enable flex grow/shrink */
      width: auto; /* Let flexbox determine width */
      /* margin-bottom remains 0 (handled by gap) */
  }

  .news-detail-sidebar-description {
    width: var(--news-sidebar-width);
    flex-shrink: 0; /* Prevent sidebar from shrinking */
    /* margin-bottom remains 0 (handled by gap) */
  }

   /* Adjust header padding on larger screens */
   .news-detail-header-description {
      padding: calc(var(--news-spacing-unit) * 2) calc(var(--news-spacing-unit) * 2.5);
    }
    /* Adjust body padding on larger screens */
    .news-detail-body-description {
      padding: 0 calc(var(--news-spacing-unit) * 2.5);
    }
    /* Adjust share padding */
     .social-share-description {
       margin-left: calc(var(--news-spacing-unit) * 2.5);
       margin-right: calc(var(--news-spacing-unit) * 2.5);
    }
     /* Adjust comment padding */
     .comment-section-description {
       padding-left: calc(var(--news-spacing-unit) * 2.5);
       padding-right: calc(var(--news-spacing-unit) * 2.5);
     }
}

/* Adjustments for very large screens if needed */
@media (min-width: 1400px) {
    .container-description {
        max-width: 1320px;
    }
}


/* --- Utilities --- */
.sr-only-description { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; border: 0; }

/* Loading/Error states */
.news-detail-loading-description, .news-detail-error-description {
  text-align: center; padding: 60px 20px; font-size: 1.3rem; color: var(--news-text-light); font-family: var(--news-font-meta);
}
.news-detail-error-description { color: #e74c3c; font-weight: 600; }


/* Highlight style for spoken word */
.highlighted-word {
  background-color: yellow;
  /* font-weight: bold; Optional */
   padding: 0 1px; /* Minimal padding */
   margin: 0 -1px; /* Counteract padding */
   border-radius: 2px;
   box-decoration-break: clone; /* Handles wrapping better */
  -webkit-box-decoration-break: clone;
}

/* Ensure spans are treated correctly for highlighting */
.news-detail-body-description span {
    display: inline; /* Default, but ensure it's not overridden */
}
/* Highlight the currently spoken word */
.news-detail-content-description span.highlighted-word {
  background-color: yellow;
  color: black;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}
