/* ChangePassword.css */

.change-password-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.change-password-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
    text-align: center;
}

.success-message {
    color: green;
    margin-bottom: 1rem;
    padding: 0.75rem;
    background-color: #e9faee;
    border: 1px solid #b3e6c9;
    border-radius: 4px;
}

.error-message {
    color: red;
    margin-bottom: 1rem;
    padding: 0.75rem;
    background-color: #fdeded;
    border: 1px solid #f5c6c6;
    border-radius: 4px;
}

.change-password-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    font-size: 1rem;
    color: #555;
    margin-bottom: 0.5rem;
}

.form-group input {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
}

.form-group input:focus {
    border-color: #66b3ff; /* Highlight on focus */
    outline: none; /* Remove default focus outline */
    box-shadow: 0 0 0 0.2rem rgba(102, 179, 255, 0.25); /* Add a subtle shadow on focus */
}

.submit-button {
    background: #3c8dbc; 
       color: white;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.submit-button:hover {
    background: #3298d3}

/* Responsive Design */
@media (max-width: 768px) {
    .change-password-container {
        padding: 1.5rem;
        margin: 1rem;
    }

    .change-password-title {
        font-size: 1.75rem;
    }

    .form-group label {
        font-size: 0.9rem;
    }

    .form-group input {
        padding: 0.6rem;
        font-size: 0.9rem;
    }

    .submit-button {
        padding: 0.6rem 1.2rem;
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .change-password-container {
        padding: 1rem;
        margin: 0.5rem;
        margin-left: 64px;
    }

    .change-password-title {
        font-size: 1.5rem;
    }
}
/* Changepassword.css */

/* Existing styles */

.password-strength {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border-radius: 4px;
    text-align: center;
    font-size: 0.9rem;
  }
  
  .password-strength.weak {
    background-color: #ffebee;
    color: #d32f2f;
    border: 1px solid #d32f2f;
  }
  
  .password-strength.normal {
    background-color: #fff3cd;
    color: #8b5cf6;
    border: 1px solid #8b5cf6;
  }
  
  .password-strength.good {
    background-color: #e8f5e9;
    color: #388e3c;
    border: 1px solid #388e3c;
  }
  
  .password-strength.perfect {
    background-color: #e8f5e9;
    color: #1a237e;
    border: 1px solid #1a237e;
  }
  /* Changepassword.css */

/* Existing styles */

.password-input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .password-input {
    width: 100%;
    padding-right: 3rem; /* Make space for the toggle button */
  }
  
  .password-toggle-button {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    color: #777;
  }
  
  .password-toggle-button:hover {
    color: #555;
  }