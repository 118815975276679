/* src/Pages/CategoryNewsPage/CategoryNewsPage.css */
/* Enhanced Styling for Elegance & Uniqueness */

/* --- Fonts & Variables --- */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Lato:wght@400;700&display=swap'); /* Example fonts */

:root {
    /* Fonts */
    --categorynewsabout-font-serif: 'Merriweather', serif;
    --categorynewsabout-font-sans: 'Lato', sans-serif;

    /* Colors - Example Palette (Customize!) */
    --categorynewsabout-primary: #2a9d8f; /* Teal */
    --categorynewsabout-primary-dark: #264653; /* Dark Blue/Green */
    --categorynewsabout-secondary: #e9c46a; /* Yellow Ochre Accent */
    --categorynewsabout-accent: #f4a261; /* Sandy Brown Accent */
    --categorynewsabout-danger: #e76f51; /* Coral Red for Errors */

    --categorynewsabout-text-dark: #333333;
    --categorynewsabout-text-medium: #585858;
    --categorynewsabout-text-light: #7a7a7a;
    --categorynewsabout-bg-body: #fdfdfd; /* Slightly off-white */
    --categorynewsabout-bg-card: #ffffff;
    --categorynewsabout-bg-widget: #f8f9fa; /* Light gray for widgets */
    --categorynewsabout-border-light: #eef0f2;
    --categorynewsabout-border-medium: #dee2e6;

    /* Shadows */
    --categorynewsabout-shadow-soft: 0 3px 8px rgba(0, 0, 0, 0.06);
    --categorynewsabout-shadow-medium: 0 6px 15px rgba(0, 0, 0, 0.1);

    /* Spacing */
    --categorynewsabout-spacing-xs: 5px;
    --categorynewsabout-spacing-sm: 10px;
    --categorynewsabout-spacing-md: 15px;
    --categorynewsabout-spacing-lg: 20px;
    --categorynewsabout-spacing-xl: 30px;
}

/* --- Base Styles --- */
body { /* Apply base font to body if not done elsewhere */
    font-family: var(--categorynewsabout-font-sans);
    color: var(--categorynewsabout-text-medium);
    background-color: var(--categorynewsabout-bg-body);
}

.categorynewsabout-page {
    padding: var(--categorynewsabout-spacing-xl) 0;
}

.categorynewsabout-container {
    max-width: 1250px; /* Slightly wider container */
    margin: 0 auto;
    padding: 0 var(--categorynewsabout-spacing-lg);
}

/* --- Title Bar --- */
.categorynewsabout-title-bar {
    border-bottom: 3px solid var(--categorynewsabout-primary);
    margin-bottom: var(--categorynewsabout-spacing-xl);
    padding-bottom: var(--categorynewsabout-spacing-md);
}

.categorynewsabout-page-title {
    font-family: var(--categorynewsabout-font-serif); /* Use serif font */
    font-size: 2.4rem;
    font-weight: 700;
    color: var(--categorynewsabout-primary-dark);
    margin: 0;
    line-height: 1.2;
}

/* --- Layout Grid --- */
.categorynewsabout-layout-grid {
    display: grid;
    gap: var(--categorynewsabout-spacing-xl);
    grid-template-columns: 1fr; /* Mobile first */
}

/* --- Main Content & News Grid --- */
.categorynewsabout-main-content {
    /* Takes space defined by grid */
}

.categorynewsabout-news-grid {
    display: grid;
    gap: var(--categorynewsabout-spacing-xl);
    grid-template-columns: 1fr; /* Mobile first */
}

/* --- News Card - Enhanced --- */
.categorynewsabout-news-card {
    background-color: var(--categorynewsabout-bg-card);
    border-radius: 8px;
    box-shadow: var(--categorynewsabout-shadow-soft);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.categorynewsabout-news-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--categorynewsabout-shadow-medium);
}

/* Image */
.categorynewsabout-news-card-image-wrapper {
    position: relative;
    display: block;
    aspect-ratio: 16 / 9.5; /* Adjust aspect ratio */
    overflow: hidden;
}

.categorynewsabout-news-card-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease, filter 0.3s ease;
}
.categorynewsabout-news-card:hover .categorynewsabout-news-card-image {
    transform: scale(1.05);
    filter: brightness(1.03);
}

/* Category Badge */
.categorynewsabout-news-card-category-badge {
    position: absolute;
    top: var(--categorynewsabout-spacing-md);
    left: var(--categorynewsabout-spacing-md);
    background-color: var(--categorynewsabout-primary); /* Use primary color */
    color: #fff;
    padding: var(--categorynewsabout-spacing-xs) var(--categorynewsabout-spacing-sm);
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 20px; /* Pill shape */
    letter-spacing: 0.5px;
    z-index: 1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Card Content */
.categorynewsabout-news-card-content {
    padding: var(--categorynewsabout-spacing-lg);
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Allows footer to stick to bottom */
}

.categorynewsabout-news-card-title {
    font-family: var(--categorynewsabout-font-serif);
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.35;
    margin: 0 0 var(--categorynewsabout-spacing-sm) 0;
    color: var(--categorynewsabout-text-dark);
}
.categorynewsabout-news-card-title a {
    color: inherit; text-decoration: none; transition: color 0.2s ease;
}
.categorynewsabout-news-card-title a:hover {
    color: var(--categorynewsabout-primary);
}

/* Meta Info (Date/Author) */
.categorynewsabout-news-card-meta {
    display: flex;
    flex-wrap: wrap;
    gap: var(--categorynewsabout-spacing-md);
    font-size: 0.8rem;
    color: var(--categorynewsabout-text-light);
    margin-bottom: var(--categorynewsabout-spacing-md);
}
.categorynewsabout-news-card-meta span {
    display: inline-flex;
    align-items: center;
}
.categorynewsabout-news-card-meta i {
    margin-right: 6px;
    color: var(--categorynewsabout-primary); /* Icon color */
}

/* Description */
.categorynewsabout-news-card-description {
    font-size: 0.95rem;
    line-height: 1.65;
    color: var(--categorynewsabout-text-medium);
    margin-bottom: var(--categorynewsabout-spacing-lg);
    flex-grow: 1; /* Pushes read more down */
    /* Line clamp */
    overflow: hidden; text-overflow: ellipsis; display: -webkit-box;
    -webkit-line-clamp: 2; -webkit-box-orient: vertical;
}

/* Read More Link Container */
.categorynewsabout-news-card-read-more-link {
    margin-top: auto; /* Pushes to bottom */
    padding-top: var(--categorynewsabout-spacing-sm);
    border-top: 1px solid var(--categorynewsabout-border-light);
}
.categorynewsabout-news-card-read-more-link a {
    display: inline-flex; /* Align icon properly */
    align-items: center;
    font-size: 0.9rem;
    font-weight: 700;
    color: var(--categorynewsabout-primary);
    text-decoration: none;
    transition: color 0.2s ease, transform 0.2s ease;
}
.categorynewsabout-news-card-read-more-link a:hover {
    color: var(--categorynewsabout-primary-dark);
    transform: translateX(3px); /* Subtle move */
}
.categorynewsabout-news-card-read-more-link i {
    margin-left: 6px;
    font-size: 0.8em;
    transition: transform 0.2s ease;
}
/* .categorynewsabout-news-card-read-more-link a:hover i { transform: translateX(2px); } */


/* --- Sidebar --- */
.categorynewsabout-sidebar {
    /* Spacing handled by grid gap */
}

.categorynewsabout-sidebar-widget {
    background-color: var(--categorynewsabout-bg-widget);
    border-radius: 6px;
    padding: var(--categorynewsabout-spacing-lg);
    margin-bottom: var(--categorynewsabout-spacing-xl);
    border: 1px solid var(--categorynewsabout-border-light);
}

.categorynewsabout-widget-title {
    font-family: var(--categorynewsabout-font-sans); /* Sans-serif for widget titles */
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--categorynewsabout-primary-dark);
    margin: 0 0 var(--categorynewsabout-spacing-lg) 0;
    padding-bottom: var(--categorynewsabout-spacing-sm);
    border-bottom: 2px solid var(--categorynewsabout-primary); /* Thicker border */
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

/* Latest News Widget Item */
.categorynewsabout-latest-news-item {
    display: flex; align-items: center;
    margin-bottom: var(--categorynewsabout-spacing-lg);
    text-decoration: none; color: inherit;
    transition: background-color 0.2s ease;
    padding-bottom: var(--categorynewsabout-spacing-lg);
    border-bottom: 1px dashed var(--categorynewsabout-border-medium);
}
.categorynewsabout-latest-news-item:last-child {
    margin-bottom: 0; border-bottom: none; padding-bottom: 0;
}
.categorynewsabout-latest-news-item:hover {
   /* Optional hover state */
   /* background-color: #f0f0f0; */
}
.categorynewsabout-latest-news-item:hover .categorynewsabout-latest-news-item-title {
    color: var(--categorynewsabout-primary);
}

.categorynewsabout-latest-news-item-image {
    flex-shrink: 0; width: 75px; height: 55px;
    margin-right: var(--categorynewsabout-spacing-md);
    overflow: hidden; border-radius: 4px;
}
.categorynewsabout-latest-news-item-image img {
    width: 100%; height: 100%; object-fit: cover;
}

.categorynewsabout-latest-news-item-content { flex-grow: 1; }

.categorynewsabout-latest-news-item-title {
    font-size: 0.95rem; font-weight: 700;
    line-height: 1.4; margin: 0 0 5px 0;
    color: var(--categorynewsabout-text-dark);
    transition: color 0.2s ease;
}

.categorynewsabout-latest-news-item-date {
    font-size: 0.8rem; color: var(--categorynewsabout-text-light);
    display: inline-flex; align-items: center;
}
.categorynewsabout-latest-news-item-date i {
    margin-right: 5px; font-size: 0.9em;
    color: var(--categorynewsabout-text-light); /* Keep icon subtle */
}

/* Ad Widget */
.categorynewsabout-ad-widget { border: none; padding: 0; background: none; } /* Remove widget styling for ad */
.categorynewsabout-ad-widget .categorynewsabout-widget-title { display: none; } /* Hide title for ad */
.categorynewsabout-ad-widget-image {
    max-width: 100%; height: auto; border-radius: 5px; display: block;
    box-shadow: var(--categorynewsabout-shadow-soft); /* Add subtle shadow to ad */
}

/* --- Status Indicators (Loading/Error/No Results) --- */
.categorynewsabout-status-indicator {
    text-align: center;
    padding: var(--categorynewsabout-spacing-xl) var(--categorynewsabout-spacing-lg);
    font-size: 1.1rem;
    border-radius: 6px;
    margin-bottom: var(--categorynewsabout-spacing-xl);
    display: flex; align-items: center; justify-content: center;
    gap: var(--categorynewsabout-spacing-sm);
}
.categorynewsabout-loading-indicator {
    background-color: var(--categorynewsabout-bg-widget);
    color: var(--categorynewsabout-text-medium);
}
.categorynewsabout-error-message {
    background-color: #fff0f0;
    color: var(--categorynewsabout-danger);
    border: 1px solid var(--categorynewsabout-danger);
}
.categorynewsabout-no-results-message {
    background-color: var(--categorynewsabout-bg-widget);
    color: var(--categorynewsabout-text-light);
}

.categorynewsabout-spinner {
    /* Spinner styles remain the same */
    margin: 0; border: 4px solid rgba(0,0,0,0.1); width: 24px; height: 24px; border-radius: 50%; border-left-color: var(--categorynewsabout-primary); animation: categorynewsabout-spin 1s ease infinite;
}
@keyframes categorynewsabout-spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
.categorynewsabout-widget-status { /* Simpler status for widgets */
    text-align: center; padding: var(--categorynewsabout-spacing-lg) 0; color: var(--categorynewsabout-text-light); font-size: 0.9rem;
}
.categorynewsabout-widget-error { color: var(--categorynewsabout-danger); }

/* --- Responsiveness --- */
@media (min-width: 576px) {
    .categorynewsabout-news-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
    }
    .categorynewsabout-page-title { font-size: 2.8rem; }
}

@media (min-width: 992px) { /* Apply 2-column layout earlier */
    .categorynewsabout-layout-grid {
        grid-template-columns: 2.5fr 1fr; /* Content wider than sidebar */
    }
     /* Keep 2 cards per row or change to 3 if space allows */
     .categorynewsabout-news-grid {
         /* grid-template-columns: repeat(3, 1fr); */ /* Uncomment for 3 columns */
     }
}

@media (min-width: 1200px) {
    .categorynewsabout-page-title { font-size: 3rem; }
}

/* src/Pages/CategoryNewsPage/CategoryNewsPage.css */

/* ... your existing styles ... */

/* --- Pagination Controls --- */
.categorynewsabout-pagination-controls {
    display: flex;
    justify-content: space-between; /* Or center, flex-end */
    align-items: center;
    margin-top: 2rem; /* Space above the controls */
    padding: 1rem 0;
    border-top: 1px solid #eee; /* Optional separator line */
}

.categorynewsabout-pagination-button {
    background-color: #007bff; /* Example button color */
    color: white;
    border: none;
    padding: 0.6rem 1.2rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s ease;
    display: inline-flex; /* Align icon and text */
    align-items: center;
    gap: 0.4rem; /* Space between icon and text */
}

.categorynewsabout-pagination-button:hover:not(:disabled) {
    background-color: #0056b3; /* Darker shade on hover */
}

.categorynewsabout-pagination-button:disabled {
    background-color: #cccccc; /* Grey out disabled button */
    color: #666666;
    cursor: not-allowed;
}

.categorynewsabout-pagination-info {
    font-size: 0.95rem;
    color: #555;
    margin: 0 1rem; /* Space around the page info text */
}

/* Optional: Adjust icon alignment if needed */
.categorynewsabout-pagination-button i {
    line-height: 1; /* Helps vertical alignment */
}

/* --- Optional: Responsive adjustments --- */
@media (max-width: 500px) {
    .categorynewsabout-pagination-controls {
        flex-direction: column; /* Stack controls vertically */
        gap: 0.8rem; /* Add space between stacked items */
    }
    .categorynewsabout-pagination-info {
        order: -1; /* Move page info text to the top on small screens */
        margin-bottom: 0.5rem;
    }
    .categorynewsabout-pagination-button {
        width: 100%; /* Make buttons full width */
        justify-content: center; /* Center button text/icon */
    }
}