/* src/components/Weather.css */

/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* Variables */
:root {
  --weather-font-primary: 'Inter', sans-serif;
  /* Background is now dynamic, these are fallbacks/defaults */
  --weather-bg-default: linear-gradient(135deg, #ACB6E5 0%, #74ebd5 100%);

  /* Text Colors - Primarily Light for Glassmorphism */
  --weather-text-light: #f8f9fa;
  --weather-text-dark-accent: #34495e; /* For specific accents if needed */
  --weather-text-medium-muted: rgb(248, 249, 250); /* Muted light text */

  /* Glassmorphism Styles */
  --weather-glass-bg: rgba(255, 255, 255, 0.15);
  --weather-glass-bg-hover: rgba(255, 255, 255, 0.25);
  --weather-glass-border: rgba(255, 255, 255, 0.2);
  --weather-glass-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.2); /* Softer shadow */
  --weather-glass-blur: 10px;

  /* Input Styles (Less transparent for usability) */
  --weather-input-bg: rgba(255, 255, 255, 0.8);
  --weather-input-bg-focus: rgba(255, 255, 255, 0.95);
  --weather-input-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  --weather-input-text: #333333;
  --weather-input-placeholder: #6c757d;
  --weather-focus-ring: rgba(66, 133, 244, 0.3); /* Google blue focus */
  --weather-focus-border: rgba(66, 133, 244, 0.5);

  --weather-error-bg: rgba(248, 215, 218, 0.8);
  --weather-error-text: #721c24;
  --weather-error-border: rgba(245, 198, 203, 0.8);

  --weather-card-radius-lg: 24px;
  --weather-card-radius-md: 16px;
  --weather-transition-speed: 0.3s;
  --weather-animation-timing: ease-in-out;
}



.weather-main-container {
  font-family: var(--weather-font-primary);
  line-height: 1.5;
  min-height: 100vh;
  /* Default Text Color */
  color: var(--weather-text-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  /* Apply default background */
  background: var(--weather-bg-default);
  transition: background 1s ease-in-out; /* Smooth background transition */
}

/* --- Dynamic Background Classes --- */
.weather-main-container.weather-bg-clear {
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%); /* Light Blue Sky */
}
.weather-main-container.weather-bg-clouds {
  background: linear-gradient(135deg, #2e4553 0%, #9bbacc 100%); /* Light Clouds */
}
.weather-main-container.weather-bg-overcast {
  background: linear-gradient(135deg, #606c88 0%, #3f4c6b 100%); /* Darker Grey Clouds */
}
.weather-main-container.weather-bg-rain,
.weather-main-container.weather-bg-drizzle {
  background: linear-gradient(135deg, #485563 0%, #29323c 100%); /* Dark Rainy */
}
.weather-main-container.weather-bg-thunderstorm {
   background: linear-gradient(135deg, #141E30 0%, #243B55 100%); /* Very Dark Storm */
}
.weather-main-container.weather-bg-snow {
   background: linear-gradient(135deg, #e0eafc 0%, #cfdef3 100%); /* Light Snowy */
}
.weather-main-container.weather-bg-atmosphere { /* Mist, Fog, Haze etc. */
   background: linear-gradient(135deg, #757f9a 0%, #d7dde8 100%); /* Greyish/Misty */
}

/* Content Wrapper */
.weather-content-wrapper {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

/* Search Input & Button */
.weather-search-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin-bottom: 2rem;
}
.weather-search {
  width: 100%;
  padding: 0.8rem 3rem 0.8rem 1.5rem;
  font-size: 1rem;
  font-family: inherit;
  color: var(--weather-input-text); /* Use specific input text color */
  background-color: var(--weather-input-bg);
  border: 1px solid transparent; /* Hide default border initially */
  border-radius: 50px;
  box-shadow: var(--weather-input-shadow);
  transition: all var(--weather-transition-speed) var(--weather-animation-timing);
  outline: none;
}
.weather-search::placeholder { color: var(--weather-input-placeholder); opacity: 0.8; }
.weather-search:focus {
  background-color: var(--weather-input-bg-focus);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08), 0 0 0 2px var(--weather-focus-ring);
  border-color: var(--weather-focus-border);
}
.weather-search-button {
  position: absolute; right: 5px; top: 50%; transform: translateY(-50%);
  display: flex; align-items: center; justify-content: center;
  padding: 0.6rem; background-color: transparent; border: none;
  font-weight: bolder;
  color: var(#fff); cursor: pointer; border-radius: 50%;
  transition: color var(--weather-transition-speed), background-color var(--weather-transition-speed);
}
.weather-search-button:hover { color: var(#fff); background-color: rgba(66, 133, 244, 0.1); }

/* Status Messages (Loading/Error) */
.weather-status-container { width: 100%; min-height: 40px; display: flex; justify-content: center; align-items: center; margin-bottom: 1rem; }
.weather-status {
  width: auto; max-width: 100%; padding: 0.6rem 1.2rem;
  border-radius: var(--weather-card-radius-md); text-align: center;
  font-weight: 500; font-size: 0.9rem; display: flex; align-items: center; gap: 0.6rem;
  animation: weather-fadeIn 0.5s ease-out;
  /* Style with glass effect */
  background: var(--weather-glass-bg);
  backdrop-filter: blur(5px); /* Less blur for status */
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid var(--weather-glass-border);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: var(--weather-text-light); /* Light text */
}
.weather-error {
    /* Override for error visibility */
    background: var(--weather-error-bg);
    color: var(--weather-error-text);
    border-color: var(--weather-error-border);
    backdrop-filter: none; /* Remove blur for solid error */
    -webkit-backdrop-filter: none;
}
.weather-spinner { width: 16px; height: 16px; border: 2px solid rgba(248, 249, 250, 0.5); border-top-color: var(--weather-text-light); border-radius: 50%; animation: weather-spin 1s linear infinite; }
@keyframes weather-fadeIn { from { opacity: 0; transform: translateY(5px); } to { opacity: 1; transform: translateY(0); } }
@keyframes weather-spin { to { transform: rotate(360deg); } }

/* --- Glassmorphism Base Style --- */
.weather-glass {
  background: var(--weather-glass-bg);
  border-radius: var(--weather-card-radius-lg); /* Use consistent radius */
  border: 1px solid var(--weather-glass-border);
  box-shadow: var(--weather-glass-shadow);
  backdrop-filter: blur(var(--weather-glass-blur));
  -webkit-backdrop-filter: blur(var(--weather-glass-blur));
  transition: background var(--weather-transition-speed), transform var(--weather-transition-speed), box-shadow var(--weather-transition-speed);
}
.weather-glass:hover {
  background: var(--weather-glass-bg-hover);
}

/* Current Weather Card */
.weather-card-current {
  width: 100%;
  padding: 2rem 2.5rem;
  margin-bottom: 2.5rem;
  /* Inherits .weather-glass */
  animation: weather-cardEnter 0.6s var(--weather-animation-timing) backwards;
  animation-delay: 0.1s;
}
@keyframes weather-cardEnter { from { opacity: 0; transform: translateY(15px); } to { opacity: 1; transform: translateY(0); } }

.weather-current-header { text-align: left; margin-bottom: 1.5rem; }
.weather-city-name { font-size: 2.2rem; font-weight: 600; line-height: 1.2; color: var(--weather-text-light); margin-bottom: 0.2rem; text-shadow: 1px 1px 3px rgba(0,0,0,0.2); }
.weather-date { font-size: 0.9rem; color: var(--weather-text-medium-muted); }

.weather-current-main { display: flex; align-items: center; justify-content: flex-start; gap: 2rem; margin-bottom: 2rem; flex-wrap: wrap; }
.weather-current-icon { width: 100px; height: 100px; object-fit: contain; filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2)); }
.weather-current-icon.is-clear { animation: weather-rotate 25s linear infinite; } /* Keep animation */
@keyframes weather-rotate { from { transform: rotate(0deg); } to { transform: rotate(360deg); } }

.weather-current-temp-details { text-align: left; }
.weather-current-temp { font-size: 4rem; font-weight: 600; color: var(--weather-text-light); line-height: 1; text-shadow: 2px 2px 5px rgba(0,0,0,0.25); }
.weather-degree { font-size: 0.4em; font-weight: 500; vertical-align: super; margin-left: 2px; }
.weather-degree-small { font-size: 0.6em; font-weight: 500; vertical-align: super; margin-left: 1px; }
.weather-current-description { font-size: 1.1rem; color: var(--weather-text-medium-muted); text-transform: capitalize; margin-top: 0.25rem; }

/* Details Grid */
.weather-details-grid { display: grid; grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); gap: 1rem; padding-top: 1.5rem; border-top: 1px solid var(--weather-glass-border); /* Use glass border */ }
.weather-detail-item {
  padding: 1rem;
  display: flex; align-items: center; gap: 0.8rem;
  /* Inherit .weather-glass */
  border-radius: var(--weather-card-radius-md); /* Smaller radius for items */
  backdrop-filter: blur(5px); /* Slightly less blur? Optional */
  -webkit-backdrop-filter: blur(5px);
}
/* No need for separate hover background if using .weather-glass:hover */

.weather-detail-icon { color: var(--weather-text-medium-muted); flex-shrink: 0; }
.weather-detail-text { display: flex; flex-direction: column; align-items: flex-start; line-height: 1.3; }
.weather-detail-label {font-weight: bold; font-size: 0.8rem; color: var(--weather-text-medium-muted); margin-bottom: 0.1rem; }
.weather-detail-value { font-size: 1rem; font-weight: 500; color: var(--weather-text-light); }

/* Forecast Section */
.weather-forecast-section {
  width: 100%;
  margin-top: 1rem;
  /* Apply glass effect to the whole section container */
  padding: 1.5rem;
  /* Inherits .weather-glass */
  border-radius: var(--weather-card-radius-lg);
  animation: weather-fadeIn 0.8s 0.3s ease-out forwards; /* Fade in after current card */
}
.weather-forecast-title { text-align: left; font-size: 1.3rem; font-weight: 600; color: var(--weather-text-light); margin-bottom: 1rem; text-shadow: 1px 1px 2px rgba(0,0,0,0.2); }

.weather-forecast-items { display: grid; grid-template-columns: repeat(auto-fill, minmax(217px, 1fr)); gap: 1rem; }
.weather-forecast-item {
  padding: 1rem 0.8rem;
  display: flex; flex-direction: column; align-items: center; text-align: center;
  /* Inherit .weather-glass */
  border-radius: var(--weather-card-radius-md); /* Smaller radius */
  animation: weather-fadeIn 0.5s ease-out backwards; /* Keep fade in, but delay set inline */
}
.weather-forecast-item:hover {
    transform: translateY(-4px); /* Keep hover lift */
    /* Hover background handled by .weather-glass:hover */
    box-shadow: 0 10px 35px 0 rgba(31, 38, 135, 0.3); /* Enhanced shadow on hover */
}

/* Forecast Item Content - Ensure light text */
.forecast-day { font-size: 0.9rem; font-weight: 600; color: var(--weather-text-light); margin-bottom: 0.5rem; }
.forecast-icon { width: 55px; height: 55px; margin-bottom: 0.5rem; filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2)); }
.forecast-temp { font-size: 1.5rem; font-weight: 600; color: var(--weather-text-light); margin-bottom: 0.5rem; }
.forecast-detail { font-size: 0.75rem; color: var(--weather-text-medium-muted); line-height: 1.3; margin-bottom: 0.1rem; }
.forecast-condition { font-size: 0.8rem; color: var(--weather-text-medium-muted); text-transform: capitalize; margin-top: 0.25rem; }

/* Footer */
.weather-footer { width: 100%; text-align: center; margin-top: 3rem; font-size: 0.8rem; color: var(--weather-text-medium-muted); opacity: 0.7; }

/* Responsive Adjustments (Review carefully for glassmorphism) */
@media (max-width: 767.98px) {
  html { font-size: 15px; }
  .weather-content-wrapper { max-width: 95%; }
  .weather-card-current { padding: 1.8rem 2rem; }
  .weather-city-name { font-size: 2rem; }
  .weather-current-temp { font-size: 3.5rem; }
  .weather-current-icon { width: 85px; height: 85px; }
  .weather-details-grid { grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); gap: 0.8rem; }
  .weather-detail-item { padding: 0.8rem; }
  .weather-forecast-section { padding: 1.2rem; }
  .weather-forecast-items { grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); }
  .weather-forecast-item { padding: 0.8rem 0.6rem; }
}
@media (max-width: 575.98px) {
  html { font-size: 14px; }
  .weather-main-container { padding: 1rem 0.5rem; }
  .weather-card-current { padding: 1.5rem; border-radius: var(--weather-card-radius-md); }
  .weather-current-main { flex-direction: column; align-items: center; gap: 0.5rem; }
  .weather-current-temp-details { text-align: center; }
  .weather-current-icon { width: 80px; height: 80px; }
  .weather-current-temp { font-size: 3rem; }
  .weather-details-grid { grid-template-columns: repeat(2, 1fr); gap: 0.6rem; }
  .weather-detail-item { padding: 0.7rem; gap: 0.5rem; border-radius: 12px;}
  .weather-forecast-section { padding: 1rem; }
  .weather-forecast-items { grid-template-columns: repeat(auto-fill, minmax(110px, 1fr)); gap: 0.6rem;}
  .weather-forecast-item { padding: 0.7rem 0.5rem; border-radius: 12px; }
}