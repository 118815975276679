/* AddNewsArticle.css */

/* ListNewNews.css */

/* Style for the modal overlay */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top of everything */
}

/* Style for the modal content */
.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative; /* For positioning the close button */
    width: 80%; /* Adjust as needed */
    max-width: 600px; /* Maximum width */
    max-height: 700px; /* Set a maximum height for the modal content */
    overflow-y: auto; /* Add a vertical scrollbar when content overflows */
}

/* Style for the close button */
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: #aaa;
}

.close:hover {
    color: black;
}

/* Basic form styles */
.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="date"],
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Important for width */
}

.form-group input[type="checkbox"] {
    margin-right: 5px;
}

/* Style for the form actions (buttons) */
.form-actions {
    text-align: right;
    margin-top: 20px;
}

.form-actions button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form-actions button:first-child {
    background-color: #5cb85c; /* Green */
    color: white;
}

.form-actions button:last-child {
    background-color: #f44336; /* Red */
    color: white;
}

/* Responsive adjustments (example) */
@media (max-width: 768px) {
    .modal-content {
        width: 95%;
        max-width: none;
    }
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

.message_new {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    text-align: center;
}

.news_form_new {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.form_group_new {
    margin-bottom: 15px;
}

.label_new {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    display: flex;
        gap: 8px;
}

/* Flex container for Category, Title, and Image */
.category_title_image_container_new {
    /* display: flex;
    flex-wrap: wrap; 
    gap: 10px;  */
    align-items: center; /* Align items vertically */
}

.category_title_image_container_new > * {
    flex: 1 1 300px; /* Distribute space evenly, initial size of 300px */
}

/* Flex container for View Side */
.view_side_container_new {
    display: flex;
    gap: 20px;
    align-items: center;
}

/* Flex container for Date and Time */
.date_time_container_new {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: 70px;
}

/* Flex container for Website links */
.website_container_new {
    display: flex;
    gap: 20px;
    align-items: center;
}

/* Flex container for tags */
.tags_container_new {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.tags_container_new label {
    margin-right: 10px;
}

input[type="text"],
input[type="file"],
input[type="date"],
select,
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    margin-top: 5px;
}

select {
    height: 40px;
}

textarea {
    resize: vertical;
}

.submit_button_new {
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
}

.submit_button_new:hover {
    background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
    .container_new {
        width: 95%;
    }

    .category_title_image_container_new,
    .view_side_container_new,
    .date_time_container_new,
    .website_container_new,
    .tags_container_new {
        flex-direction: column; /* Stack items vertically on smaller screens */
        align-items: flex-start;
    }

    .category_title_image_container_new > * {
        flex: 1 1 100%; /* Each item takes full width */
    }

    input[type="text"],
    input[type="file"],
    input[type="date"],
    select,
    textarea {
        font-size: 14px;
    }

    .submit_button_new {
        font-size: 16px;
    }
}


/* Responsive Design */
@media (max-width: 768px) {
.date_time_container_new {
    
    margin-left: 0px;
}
}


/* AdminNewsApproval.css */

/* Container and General Layout */
.admin-approval-container {
    max-width: 1200px; /* Adjust max width as needed */
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.admin-approval-container h1 {
    text-align: center;
    margin-bottom: 25px;
    color: #333;
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
}

.approval-section {
    margin-bottom: 30px;
}

.approval-section h2 {
    margin-bottom: 15px;
    color: #444;
    font-size: 1.4em;
}

/* Alert / Feedback Messages */
.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

/* Table Styles */
.table-responsive {
    overflow-x: auto; /* Enable horizontal scroll on small screens */
    margin-top: 15px;
    border: 1px solid #ddd; /* Add border around the scrollable area */
    border-radius: 5px;
}

.approval-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px; /* Minimum width before scrollbar appears */
    font-size: 0.95em;
}

.approval-table th,
.approval-table td {
    border: 1px solid #ddd;
    padding: 10px 12px; /* Adjusted padding */
    text-align: left;
    vertical-align: middle;
}

.approval-table th {
    background-color: #f2f2f2;
    font-weight: 600; /* Slightly bolder */
    color: #555;
}

.approval-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.approval-table tbody tr:hover {
    background-color: #f1f1f1;
}

/* Specific Cell Content Styles */
.reporter-name {
    font-weight: 600;
    color: #333;
    display: block; /* Ensure name is on its own line */
}

.reporter-id {
    font-size: 0.9em;
    color: #666;
}

.news-thumbnail {
    display: block; /* Prevents extra space below image */
    width: 70px;
    height: 50px; /* Fixed height */
    border-radius: 4px;
    object-fit: cover; /* Crop image nicely */
    border: 1px solid #eee;
}

.no-image {
    color: #999;
    font-style: italic;
    font-size: 0.9em;
}

.action-cell {
    text-align: center; /* Center buttons */
    white-space: nowrap; /* Prevent buttons from wrapping */
    min-width: 160px; /* Ensure space for buttons */
}

/* Button Styles */
.action-cell button {
    padding: 6px 12px;
    margin: 0 4px; /* Space between buttons */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: 500;
    transition: background-color 0.2s ease, opacity 0.2s ease;
    color: white;
}

.action-cell button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.button-approve {
    background-color: #28a745; /* Green */
    border: 1px solid #218838;
}

.button-approve:hover:not(:disabled) {
    background-color: #218838;
}

.button-reject {
    background-color: #dc3545; /* Red */
     border: 1px solid #c82333;
}

.button-reject:hover:not(:disabled) {
    background-color: #c82333;
}

.button-view, /* Optional view button style */
.button-edit,
.button-delete,
.button-secondary {
    background-color: #6c757d; /* Grey */
    border: 1px solid #5a6268;
}

.button-view:hover:not(:disabled),
.button-edit:hover:not(:disabled),
.button-delete:hover:not(:disabled),
.button-secondary:hover:not(:disabled) {
    background-color: #5a6268;
}

.button-primary { /* For modal update button */
    background-color: #007bff; /* Blue */
     border: 1px solid #0069d9;
}
.button-primary:hover:not(:disabled) {
    background-color: #0069d9;
}


/* --- Modal Styles (If using the Edit Modal) --- */

/* Reuse modal styles from previous examples if available */
/* Example minimal modal styles */
.modal {
  /* Basic modal overlay */
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.5); /* Dim background */
  display: flex; /* Use flexbox for centering */
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  margin: auto;
  padding: 25px 30px;
  border: 1px solid #888;
  width: 80%;
  max-width: 700px; /* Max width */
  border-radius: 8px;
  position: relative; /* For close button positioning */
  max-height: 90vh; /* Limit height and enable scrolling */
  overflow-y: auto; /* Enable vertical scroll if content overflows */
}

.modal-content h2 {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.close {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal .form-group {
    margin-bottom: 15px;
}
.modal .form-group label {
     display: block;
     margin-bottom: 5px;
     font-weight: bold;
}
.modal .form-group input[type="text"],
.modal .form-group input[type="date"],
.modal .form-group select,
.modal .form-group textarea,
.modal .form-group input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
.modal .form-group textarea {
    resize: vertical;
    min-height: 80px;
}
.modal .photo-preview {
    max-width: 100px;
    max-height: 100px;
    margin-top: 10px;
    border: 1px solid #eee;
    border-radius: 4px;
    object-fit: cover;
    display: block;
}

.checkbox-fieldset {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px 15px;
    margin-bottom: 15px;
}
.checkbox-fieldset legend {
    font-weight: bold;
    padding: 0 5px;
    color: #555;
}
.checkbox-fieldset .checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    font-weight: normal;
}
.checkbox-fieldset .checkbox-label input[type="checkbox"] {
    width: auto;
    margin-right: 5px; /* Consistent spacing */
}


.modal-actions {
    text-align: right;
    margin-top: 25px;
    padding-top: 15px;
    border-top: 1px solid #eee;
}
.modal-actions button {
     margin-left: 10px; /* Space between buttons */
}


/* === Addnews.css additions === */

/* Style for the table */
.news-list-table {
    width: 100%;
    border-collapse: collapse; /* Improves border appearance */
    margin-top: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.news-list-table th,
.news-list-table td {
    border: 1px solid #ddd;
    padding: 10px 12px; /* Adjust padding */
    text-align: left;
    vertical-align: middle; /* Align content vertically */
    font-size: 0.9rem; /* Adjust font size if needed */
}

.news-list-table th {
    background-color: #f8f9fa; /* Light header background */
    font-weight: 600;
    white-space: nowrap; /* Prevent headers from wrapping */
}

/* Alternating row colors */
.news-list-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.news-list-table tbody tr:hover {
    background-color: #e9ecef; /* Hover effect */
}


/* --- CSS Rule for Description Truncation --- */
.news-list-table td.description-cell {
    white-space: nowrap;       /* Prevent text from wrapping */
    overflow: hidden;          /* Hide text that overflows the cell */
    text-overflow: ellipsis;   /* Display ellipsis (...) for overflow */
    max-width: 250px;          /* IMPORTANT: Set a max-width for the cell */
                               /* Adjust this value as needed based on your design */
    cursor: default;           /* Indicate it's not directly editable here */
}
/* ------------------------------------------ */


/* Styling for image cell */
.news-list-table td.image-cell {
    width: 100px; /* Give image cell a fixed width */
    text-align: center;
}
.news-list-table td.image-cell img {
    max-width: 80px;  /* Control image size */
    max-height: 80px; /* Control image size */
    width: auto;
    height: auto;
    border-radius: 6px; /* Slightly softer corners */
    display: block;     /* Center image */
    margin: 0 auto;   /* Center image */
}

/* Styling for status cells (checkboxes) */
.news-list-table td.status-cell {
    text-align: center;
    font-size: 1.1rem; /* Make icons slightly larger */
}

/* Styling for action buttons */
.news-list-table td.action-cell {
    white-space: nowrap; /* Prevent buttons wrapping */
    text-align: center;
    width: 140px; /* Fixed width for actions */
}

.news-list-table .edit-button,
.news-list-table .delete-button {
    padding: 6px 12px;
    margin: 0 4px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.85rem;
    transition: background-color 0.2s ease;
}

.news-list-table .edit-button {
    background-color: #ffc107; /* Warning color */
    color: #333;
}
.news-list-table .edit-button:hover {
    background-color: #e0a800;
}

.news-list-table .delete-button {
    background-color: #dc3545; /* Danger color */
    color: white;
}
.news-list-table .delete-button:hover {
    background-color: #c82333;
}


/* Container and Messages */
.list-news-container {
    padding: 20px;
    max-width: 95%; /* Allow table to use more width */
    margin: 50px auto; /* Center container */
    background-color: #fff;
    border-radius: 8px;
    margin-left: 64px;
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

.message {
    padding: 10px 15px;
    margin-bottom: 15px;
    border-radius: 4px;
    font-weight: 500;
}
.error-message {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}
.success-message {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.loading-indicator {
    text-align: center;
    padding: 30px;
    font-size: 1.1rem;
    color: #666;
}

/* Responsive Table Wrapper */
.table-responsive {
    overflow-x: auto; /* Add horizontal scroll on small screens */
    margin-top: 15px;
}


/* --- Modal Styles --- */
.modal {
    display: block; /* Show the modal */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
}

.modal-content {
    background-color: #fefefe;
    margin: 5% auto; /* Centered vertically and horizontally */
    padding: 25px 30px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    max-width: 700px; /* Maximum width */
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
    position: relative;
    max-height: 85vh; /* Limit height */
    overflow-y: auto; /* Allow vertical scroll within modal */
}

.modal .close {
    color: #aaa;
    position: absolute; /* Position relative to modal-content */
    top: 10px;
    right: 20px;
    font-size: 28px;
    font-weight: bold;
}

.modal .close:hover,
.modal .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.modal h2 {
    margin-top: 0;
    margin-bottom: 25px;
    color: #333;
    text-align: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
}

.modal .form-group {
    margin-bottom: 18px;
}

.modal .form-group label {
    display: block;
    margin-bottom: 6px;
    font-weight: 600;
    color: #555;
}

.modal .form-group input[type="text"],
.modal .form-group input[type="date"],
.modal .form-group select,
.modal .form-group textarea {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Include padding and border in element's total width/height */
    font-size: 0.95rem;
}
.modal .form-group textarea {
    min-height: 100px;
    resize: vertical;
}

.modal .form-group input[type="file"] {
    padding: 8px 0;
}

.modal .modal-form-row {
    display: flex;
    gap: 20px;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}
.modal .modal-form-row .form-group {
    flex: 1; /* Distribute space */
    min-width: 200px; /* Minimum width before wrapping */
}


.modal .image-preview-container {
    margin-bottom: 10px;
}
.modal .image-preview-modal {
    max-width: 120px;
    max-height: 120px;
    width: auto;
    height: auto;
    border-radius: 6px;
    border: 1px solid #eee;
    display: block; /* Or inline-block */
}

.modal .checkbox-group {
    border: 1px solid #eee;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 18px;
}
.modal .checkbox-group legend {
    font-weight: 600;
    color: #555;
    padding: 0 8px; /* Spacing around legend */
    font-size: 0.9rem;
}
.modal .checkbox-group label {
    display: inline-flex; /* Align checkbox and text nicely */
    align-items: center;
    margin-right: 15px;
    font-weight: normal; /* Normal weight for checkbox labels */
    margin-bottom: 8px; /* Spacing between rows of checkboxes */
    cursor: pointer;
}
.modal .checkbox-group input[type="checkbox"] {
    margin-right: 6px;
    cursor: pointer;
    width: auto; /* Override potential width: 100% */
}

/* Modal Actions */
.modal .form-actions {
    text-align: right;
    margin-top: 25px;
    padding-top: 20px;
    border-top: 1px solid #eee;
}

.modal .form-actions button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.95rem;
    font-weight: 600;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.modal .update-button {
    background-color: #28a745; /* Success color */
    color: white;
}
.modal .update-button:hover {
    background-color: #218838;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.modal .cancel-button {
    background-color: #6c757d; /* Secondary color */
    color: white;
}
.modal .cancel-button:hover {
    background-color: #5a6268;
    box-shadow: 0 2px 5px rgba(0,0,0,0.15);
}


/* Styling for checkbox groups */
.form_group_new {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 4px;
}
.label_new {
    display: block; /* Makes labels stack */
    margin-bottom: 8px;
    cursor: pointer;
}
.label_new input[type="checkbox"] {
    margin-right: 8px;
    vertical-align: middle; /* Align checkbox with text */
}
.website_container_new, .tags_container_new {
    display: flex; /* Use flex for inline layout if desired */
    flex-wrap: wrap; /* Allow wrapping */
    gap: 15px; /* Space between items */
}
.tags_container_new .label_new {
    flex-basis: calc(33.33% - 10px); /* Roughly 3 per row */
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}