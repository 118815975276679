/* src/components/EPaperViewer.css */
.epaper-viewer-container {
    padding: 20px;
    max-width: 1200px;
    margin: 20px auto;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.viewer-header {
    text-align: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    position: relative;
}
.viewer-header h1 {
    margin-bottom: 5px;
}
.viewer-header p {
    color: #555;
    font-size: 0.9em;
}
.back-link {
    position: absolute;
    top: 0;
    left: 0;
    text-decoration: none;
    color: #007bff;
}
.back-link:hover {
    text-decoration: underline;
}


.viewer-controls {
    text-align: center;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #eee;
    border-radius: 5px;
}

.viewer-controls button {
    padding: 8px 15px;
    margin: 0 10px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 0.9em;
    transition: background-color 0.2s ease;
}

.viewer-controls button:hover:not(:disabled) {
    background-color: #0056b3;
}

.viewer-controls button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.viewer-controls span {
    margin: 0 10px;
    font-weight: bold;
}

.canvas-container {
    text-align: center; /* Center the canvas */
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    overflow: auto; /* Add scrollbars if canvas is bigger than container */
    position: relative; /* Needed for overlay */
    background-color: #fff; /* White background for canvas area */
    min-height: 300px; /* Ensure some height even when loading */
}

canvas {
    display: block; /* Removes extra space below canvas */
    margin: 0 auto; /* Center horizontally */
    max-width: 100%; /* Ensure canvas doesn't overflow container */
    height: auto; /* Maintain aspect ratio */
    box-shadow: 0 0 8px rgba(0,0,0,0.1);
}

.rendering-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    z-index: 10;
    color: #333;
}

.loading-indicator, .error-message {
    text-align: center;
    padding: 20px;
    font-weight: bold;
}
.error-message {
    color: red;
}

.bottom-controls {
    margin-top: 15px;
    border-top: 1px solid #eee;
    padding-top: 15px;
}

/* Add responsive styles as needed */
@media (max-width: 768px) {
    .viewer-controls button {
        padding: 6px 10px;
        margin: 0 5px;
    }
    .viewer-controls span {
        display: block; /* Stack page count on smaller screens */
        margin: 5px 0;
    }
}
/* src/components/EPaperViewer.css */

/* --- Base & Defaults --- */
:root {
    --primary-color: #f8501d; /* A modern blue */
    --primary-hover-color: #2b7fd8;
    --secondary-color: #6c757d; /* Grey for accents */
    --light-bg-color: #f8f9fa;
    --white-color: #ffffff;
    --dark-text-color: #212529;
    --medium-text-color: #495057;
    --light-text-color: #6c757d;
    --border-color: #dee2e6;
    --success-color: #28a745;
    --error-color: #dc3545;
    --error-bg-color: #f8d7da;
    --error-border-color: #f5c6cb;
    --shadow-color: rgba(0, 0, 0, 0.1);
    --focus-shadow-color: rgba(0, 123, 255, 0.25);
  
    --border-radius-sm: 0.25rem; /* 4px */
    --border-radius-md: 0.5rem;  /* 8px */
    --border-radius-lg: 0.8rem;  /* 12px */
  
    --transition-speed: 0.3s;
  }
  
  /* Apply a basic reset and font */
  body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    color: var(--dark-text-color);
    background-color: var(--light-bg-color); /* Light page background */
    margin: 0; /* Ensure Navbar/Footer touch edges if they are full-width */
  }
  
  /* --- Container Styling --- */
  .epaper-page-container {
    max-width: 1140px; /* Standard container width */
    margin: 2rem auto; /* Center the container with space top/bottom */
    padding: 2rem 2.5rem; /* Generous padding */
    background-color: var(--white-color);
    border-radius: var(--border-radius-lg);
    box-shadow: 0 5px 20px var(--shadow-color);
    opacity: 0; /* Start hidden for fade-in */
    animation: fadeInContainer 0.6s ease-out forwards;
    animation-delay: 0.2s; /* Slight delay for effect */
  }
  
  @keyframes fadeInContainer {
    from { opacity: 0; transform: translateY(15px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  
  /* --- Heading --- */
  .epaper-page-container h1 {
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 2.5rem;
    font-size: 2.8rem;
    font-weight: 700;
    letter-spacing: -0.5px;
    /* Optional: subtle text shadow */
    /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05); */
  }
  
  /* --- Date Selector --- */
  .date-selector {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping on small screens */
    justify-content: center;
    align-items: center;
    gap: 0.8rem 1.5rem; /* Vertical and horizontal gap */
    margin-bottom: 3rem;
    padding: 1.5rem;
    background-color: #fdfdff; /* Slightly off-white */
    border-radius: var(--border-radius-md);
    border: 1px solid var(--border-color);
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.04);
  }
  
  .date-selector label {
    font-weight: 600;
    color: var(--medium-text-color);
    font-size: 1.1rem;
  }
  
  .date-selector input[type="date"] {
    padding: 0.75rem 1rem;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-sm);
    font-size: 1rem;
    color: var(--dark-text-color);
    background-color: var(--white-color);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
    transition: border-color var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
    cursor: pointer;
    min-width: 180px; /* Ensure it doesn't get too small */
    /* Override inline style - it's better to control width here */
    width: auto; /* Let it size based on content or min-width */
  }
  
  /* Style the date picker icon (vendor prefixes for wider compatibility) */
  .date-selector input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 0.8;
    transition: opacity var(--transition-speed) ease;
  }
  .date-selector input[type="date"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1;
  }
  
  .date-selector input[type="date"]:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px var(--focus-shadow-color);
  }
  
  /* --- Loading & Error Messages --- */
  .loading-indicator,
  .error-message {
    text-align: center;
    padding: 1.2rem 1.5rem;
    margin: 2rem auto; /* Center and add vertical space */
    border-radius: var(--border-radius-md);
    font-weight: 500;
    max-width: 80%; /* Prevent full width */
    font-size: 1.05rem;
  }
  
  .loading-indicator {
    color: var(--medium-text-color);
    background-color: #e9ecef; /* Light grey */
    border: 1px solid #ced4da;
  }
  
  .error-message {
    color: #721c24; /* Dark red text for better contrast */
    background-color: var(--error-bg-color);
    border: 1px solid var(--error-border-color);
  }
  
  /* --- Newspaper List --- */
  .newspaper-list {
    margin-top: 3rem;
    border-top: 2px solid var(--primary-color); /* Stronger visual separator */
    padding-top: 2.5rem;
  }
  
  .newspaper-list h2 {
    color: var(--dark-text-color);
    margin-bottom: 2rem;
    font-size: 1.9rem;
    text-align: center;
    font-weight: 600;
  }
  
  .newspaper-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    gap: 1.5rem; /* Gap between items */
    /* Start with 1 column, adjust in media queries */
    grid-template-columns: 1fr;
  }
  
  .newspaper-list li {
    /* Add transition for list item appearance */
    opacity: 0;
    transform: scale(0.95);
    animation: popInItem 0.4s ease-out forwards;
  }
  
  /* Stagger the animation for list items */
  .newspaper-list li:nth-child(1) { animation-delay: 0.1s; }
  .newspaper-list li:nth-child(2) { animation-delay: 0.15s; }
  .newspaper-list li:nth-child(3) { animation-delay: 0.2s; }
  .newspaper-list li:nth-child(4) { animation-delay: 0.25s; }
  /* Add more if you expect many items */
  .newspaper-list li:nth-child(n+5) { animation-delay: 0.3s; }
  
  
  @keyframes popInItem {
    from { opacity: 0; transform: scale(0.95); }
    to { opacity: 1; transform: scale(1); }
  }
  
  
  .newspaper-link {
    display: block;
    padding: 1.2rem 1.8rem;
    background: linear-gradient(145deg, #ffffff, #f0f3f5); /* Subtle gradient */
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-md);
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 600;
    font-size: 1.1rem;
    text-align: center;
    transition:
      background var(--transition-speed) ease,
      color var(--transition-speed) ease,
      transform var(--transition-speed) ease,
      box-shadow var(--transition-speed) ease;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden; /* For potential pseudo-element effects */
  }
  
  .newspaper-link:hover,
  .newspaper-link:focus {
    background: linear-gradient(145deg, var(--primary-color), var(--primary-hover-color));
    color: var(--white-color);
    transform: translateY(-4px) scale(1.02); /* Lift and slightly enlarge */
    box-shadow: 0 8px 15px var(--shadow-color);
    border-color: transparent; /* Hide border when background is dark */
    outline: none;
  }
  
  /* Optional: Add a subtle icon/indicator */
  .newspaper-link::after {
    content: '→'; /* Or use a Font Awesome icon */
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity var(--transition-speed) ease, right var(--transition-speed) ease;
    font-weight: bold;
    color: inherit; /* Inherits color from the link */
  }
  
  .newspaper-link:hover::after,
  .newspaper-link:focus::after {
    opacity: 0.7;
    right: 1rem; /* Move arrow slightly on hover */
  }
  
  
  /* --- Responsive Design --- */
  
  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .epaper-page-container {
      max-width: 90%;
      padding: 1.8rem 2rem;
      margin: 1.5rem auto;
    }
  
    .epaper-page-container h1 {
      font-size: 2.4rem;
      margin-bottom: 2rem;
    }
  
    .newspaper-list h2 {
      font-size: 1.7rem;
    }
  
    /* Make list 2 columns on tablets */
    .newspaper-list ul {
       grid-template-columns: repeat(2, 1fr);
       gap: 1.2rem;
    }
  
    .newspaper-link {
      padding: 1rem 1.5rem;
      font-size: 1rem;
    }
  }
  
  /* Small devices (landscape phones, less than 768px) */
  @media (max-width: 767.98px) {
    .epaper-page-container {
      max-width: 95%;
      padding: 1.5rem 1.2rem;
    }
  
    .epaper-page-container h1 {
      font-size: 2rem;
    }
  
    .date-selector {
      padding: 1rem;
      /* Consider stacking if needed, but flex-wrap might be enough */
       flex-direction: column;
       align-items: stretch; /* Make items full width */
    }
  
    .date-selector label {
       text-align: center; /* Center label when stacked */
       margin-bottom: 0.5rem;
    }
  
    .date-selector input[type="date"] {
       width: 100%; /* Make input full width */
       min-width: unset; /* Remove min-width */
       box-sizing: border-box; /* Include padding in width calculation */
       text-align: center;
    }
  
    .newspaper-list h2 {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  
     /* Optional: Back to 1 column for list on smaller screens if 2 feels cramped */
    /* .newspaper-list ul {
       grid-template-columns: 1fr;
    } */
  
    .newspaper-link {
      padding: 0.9rem 1.2rem;
    }
  
    .newspaper-link::after {
       display: none; /* Hide arrow on smaller screens maybe */
    }
  }
  
  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {
     .epaper-page-container {
      padding: 1.2rem 1rem;
      margin: 1rem auto;
      border-radius: var(--border-radius-md); /* Slightly smaller radius */
    }
  
    .epaper-page-container h1 {
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
    }
  
    .loading-indicator,
    .error-message {
      max-width: 95%;
      font-size: 0.95rem;
      padding: 1rem 1.2rem;
    }
  
    .newspaper-list {
      padding-top: 1.8rem;
      margin-top: 2rem;
    }
  
    .newspaper-list h2 {
      font-size: 1.4rem;
    }
  
    /* Definitely 1 column */
    .newspaper-list ul {
       grid-template-columns: 1fr;
       gap: 1rem;
    }
  
    .newspaper-link {
      font-size: 0.95rem;
    }
  }