/* Base Styles */
.testimonial-container {
    text-align: center;
    margin: 1rem auto; /* Use rem for scalable margins */
    max-width: 90%; /* Take up most of the screen on small devices */
    padding: 1rem;
}

/* Input and Textarea Styles */
.testimonial-container input,
.testimonial-container textarea {
    display: block;
    width: 100%;
    margin: 0.5rem 0; /* Use rem for margins */
    padding: 0.75rem; /* Use rem for padding */
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    box-sizing: border-box; /* Prevent padding from affecting width */
    font-size: 1rem;
}

/* Button Styles */
.testimonial-container button {
    margin-top: 0.75rem; /* Use rem for margins */
    padding: 0.75rem 1.25rem; /* Use rem for padding */
    background: linear-gradient(to bottom, #518176, #275349);
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 1rem;
}

.testimonial-container button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

/* Table Styles */
.testimonial-table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto; /* Enable horizontal scrolling */
    display: block; /* Add this to make overflow-x work */
}

.testimonial-table th,
.testimonial-table td {
    border: 1px solid #ddd;
    padding: 0.5rem; /* Use rem for padding */
    text-align: left;
    font-size: 0.9rem;
    white-space: nowrap; /* Prevent text from wrapping */
}

/* Pagination Styles */
.pagination {
    margin-top: 1.25rem; /* Use rem for margins */
    display: flex;
    justify-content: center; /* Center the pagination buttons */

    align-items: center;
    gap: 0.5rem; /* Space between buttons */
}

.pagination button {
    padding: 0.5rem 1rem; /* Use rem for padding */
    color: black !important;
    font-weight: 700;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    cursor: pointer;
    color: #fff;
    font-size: 0.9rem;
}

.pagination button:hover {
    background-color: #258bc7;
}

.pagination button:disabled {
   
   
}

/* Image Styles */
.testimonial-table img {
    max-width: 100px; /* Limit image width */
    max-height: 100px; /* Limit image height */
    display: block;
    margin: auto;
}

/* Error Message Style */
.testimonial-container .error {
    color: red;
    margin-top: 0.5rem;
}

/* Responsive Design - Small Screens */
@media (max-width: 576px) {
    .testimonial-container {
        font-size: 0.9rem; /* Reduce font size on smaller screens */
    }

    .testimonial-table th,
    .testimonial-table td {
        font-size: 0.8rem; /* Adjust font size */
        padding: 0.3rem; /* Reduce padding */
    }

    .testimonial-table img {
        max-width: 70px; /* Further limit image width */
        max-height: 70px; /* Further limit image height */
    }

    .pagination button {
        font-size: 0.8rem; /* Adjust font size */
        padding: 0.3rem 0.75rem; /* Reduce padding */

    }
}

/* Medium Screens */
@media (min-width: 577px) and (max-width: 768px) {
    .testimonial-container {
        max-width: 95%; /* slightly scale up to use available space */
    }
}

/* Large screens - Optional - expand back out */
@media (min-width: 992px) {
    .testimonial-container {
      max-width: 1000px;
    }
  }