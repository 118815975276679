/* --- Base Variables (CSS Custom Properties) --- */
:root {
  --main-yellow: #fcb900; /* Changed from --yellow for clarity */
  --dark-gray: #222;
  --medium-gray: #777;
  --light-gray: #f1f1f1;
  --white: #fff;
  --black: #000;
}

/* --- Reset & Base Styles --- */
body {
  font-family: sans-serif; /* Add a default font */
  color: var(--dark-gray);
  line-height: 1.6;
}

a {
  color: var(--main-yellow);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--dark-gray);
  text-decoration: none; /* Ensure no underline on hover unless desired */
}

img {
  max-width: 100%;
  height: auto;
  display: block; /* Prevent bottom space */
}

.container-fluid, .container {
  padding-left: 15px;
  padding-right: 15px;
}
.paddding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

/* --- Utility Classes --- */
.fix::after { /* Clearfix */
  content: "";
  display: table;
  clear: both;
}
.pt-25 { padding-top: 25px; }
.pt-50 { padding-top: 50px; }
.pt-80 { padding-top: 80px; }
.pt-90 { padding-top: 90px; }
.pb-20 { padding-bottom: 20px; }
.pb-30 { padding-bottom: 30px; }
.pb-80 { padding-bottom: 80px; }
.pb-90 { padding-bottom: 90px; }
.pb-130 { padding-bottom: 130px; }
.mb-15 { margin-bottom: 15px; }
.mb-20 { margin-bottom: 20px; }
.mb-30 { margin-bottom: 30px; }
.mb-40 { margin-bottom: 40px; }
.mb-45 { margin-bottom: 45px; }
.mt-20 { margin-top: 20px; }
.gray-bg { background-color: #f7f7f7; } /* Slightly different gray */
.white-bg { background-color: var(--white); }
.d-flex { display: flex; }
.align-items-center { align-items: center; }
.justify-content-between { justify-content: space-between; }
.justify-content-center { justify-content: center; }
.text-center { text-align: center; }
.d-none { display: none; }
@media (min-width: 992px) { /* lg */
  .d-lg-block { display: block !important; }
}
@media (min-width: 768px) { /* md */
  .d-md-block { display: block !important; }
}
@media (min-width: 576px) { /* sm */
  .d-sm-block { display: block !important; }
}

/* --- Header & Navigation --- */
.fh5co_header_bg {
  background: var(--dark-gray);
  color: var(--white);
}

.color_fff {
  color: var(--white) !important;
}

.treding_btn {
  background: var(--main-yellow);
  color: var(--white);
  margin: 0 10px;
  display: inline-block;
  padding: 15px 40px;
  transition: background-color 0.3s ease; /* Added transition */
}

.treding_btn:hover,
.treding_btn:focus {
  background: var(--dark-gray); /* Darker hover */
  color: var(--white);
}

.fh5co_logo_width {
  width: 130px;
}

/* --- Marquee --- */
.marquee-container {
  width: 100%;
  overflow: hidden;
  background-color: var(--light-gray);
  color: var(--dark-gray);
  padding: 10px 8px; /* Reduced padding */
  font-size: 1rem; /* Adjusted font size */
  font-weight: 600; /* Slightly lighter weight */
  border-bottom: 1px solid #ddd;
}

.marquee-item {
  margin-right: 50px; /* Increased spacing */
  white-space: nowrap;
  display: inline-block;
}
.marquee-item::after { /* Add separator */
    content: "//";
    margin-left: 50px;
    color: var(--medium-gray);
    font-weight: 400;
}
.marquee-item:last-child::after {
    content: ""; /* Remove separator after last item */
    margin-left: 0;
}


/* --- Trending Area --- */
.trending-area .trending-main {
    /* Styles for the main container if needed */
}
.trending-area .trending-top {
    position: relative; /* Needed for absolute positioning of caption */
    overflow: hidden; /* Hide overflowing parts of image/caption */
}
.trending-area .trend-top-img {
    position: relative;
}
.trending-area .trend-top-img img {
    width: 100%;
    height: 450px; /* Adjust height as needed */
    object-fit: cover; /* Cover the area, might crop */
    display: block;
}
.trending-area .trend-top-cap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 30px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    color: var(--white);
}
.trending-area .trend-top-cap span {
    display: inline-block;
    padding: 5px 10px;
    margin-bottom: 10px;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
}
.trending-area .trend-top-cap span.bgr { background-color: rgba(255, 0, 0, 0.7); } /* Example color */
.trending-area .trend-top-cap span.bgb { background-color: rgba(0, 0, 255, 0.7); }
.trending-area .trend-top-cap span.bgg { background-color: rgba(0, 128, 0, 0.7); }
.trending-area .trend-top-cap span.bgy { background-color: rgba(255, 193, 7, 0.8); } /* Yellow */

.trending-area .trend-top-cap h2 {
    font-size: 1.5rem; /* Adjust size */
    margin-bottom: 5px;
    font-weight: bold;
}
.trending-area .trend-top-cap h2 a {
    color: var(--white);
}
.trending-area .trend-top-cap h2 a:hover {
    color: var(--main-yellow);
}
.trending-area .trend-top-cap p {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
}
/* Right side trending items */
.trending-area .trend-top-cap2 {
    padding: 15px 20px; /* Smaller padding */
}
.trending-area .trend-top-cap2 h2 {
    font-size: 1.1rem; /* Smaller font size */
}
.trending-area .trend-top-cap2 p {
    font-size: 0.8rem;
}
.trending-area .col-lg-4 .trend-top-img img {
    height: 210px; /* Adjust height for side images */
}

/* --- What's New Area --- */
.whats-news-area .section-tittle h3 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 0; /* Removed default margin */
}

/* Tab Buttons */
.whats-news-area .properties__button .nav-tabs {
    border-bottom: none; /* Remove default border */
    justify-content: flex-end; /* Align tabs to the right */
}
.whats-news-area .properties__button .nav-link {
    border: none;
    background: none;
    color: var(--dark-gray);
    padding: 10px 15px;
    margin-left: 5px;
    font-weight: 600;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
}
.whats-news-area .properties__button .nav-link.active,
.whats-news-area .properties__button .nav-link:hover {
    color: var(--main-yellow);
    border-bottom: 2px solid var(--main-yellow);
}

/* Tab Content */
.whats-news-area .tab-content {
    /* Styles if needed */
}
.whats-news-area .whats-news-single .whates-img img {
    width: 100%;
    height: 350px; /* Adjust height */
    object-fit: cover;
    margin-bottom: 15px;
}
.whats-news-area .whates-caption h4 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 10px;
}
.whats-news-area .whates-caption h4 a {
    color: var(--dark-gray);
}
.whats-news-area .whates-caption h4 a:hover {
    color: var(--main-yellow);
}
.whats-news-area .whates-caption span {
    font-size: 0.9rem;
    color: var(--medium-gray);
    display: block;
    margin-bottom: 10px;
}
.whats-news-area .whates-caption p {
    font-size: 1rem;
    color: var(--medium-gray);
}

/* Right Side News */
.whats-news-area .whats-right-single {
    display: flex;
    align-items: flex-start; /* Align items to the top */
}
.whats-news-area .whats-right-img {
    flex: 0 0 120px; /* Fixed width for image */
    margin-right: 15px;
}
.whats-news-area .whats-right-img img {
    width: 100%;
    height: 90px; /* Adjust height */
    object-fit: cover;
}
.whats-news-area .whats-right-cap {
    flex: 1;
}
.whats-news-area .whats-right-cap span {
    font-size: 0.7rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 3px 8px;
    display: inline-block;
    margin-bottom: 5px;
    color: var(--white);
}
/* Add specific category colors if desired */
.whats-news-area .whats-right-cap span.colorb { background-color: #007bff; } /* Blue */
.whats-news-area .whats-right-cap span.colorg { background-color: #28a745; } /* Green */
.whats-news-area .whats-right-cap span.colorr { background-color: #dc3545; } /* Red */
.whats-news-area .whats-right-cap span.colorp { background-color: #6f42c1; } /* Purple */

.whats-news-area .whats-right-cap h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
    line-height: 1.3;
}
.whats-news-area .whats-right-cap h4 a {
    color: var(--dark-gray);
}
.whats-news-area .whats-right-cap h4 a:hover {
    color: var(--main-yellow);
}
.whats-news-area .whats-right-cap p {
    font-size: 0.8rem;
    color: var(--medium-gray);
    margin: 0;
}

/* Banner Ad */
.banner-one img {
    width: 100%;
    height: auto;
}

/* Follow Us Section */
.single-follow .single-box {
    border: 1px solid #e0e0e0;
    padding: 10px;
}
.single-follow .follow-us {
    padding: 15px 10px;
    border-bottom: 1px solid #eee;
}
.single-follow .follow-us:last-child {
    border-bottom: none;
}
.single-follow .follow-social {
    margin-right: 15px;
}
.single-follow .follow-social img {
    width: 30px; /* Adjust icon size */
}
.single-follow .follow-count span {
    font-weight: bold;
    font-size: 1.1rem;
    display: block;
}
.single-follow .follow-count p {
    font-size: 0.9rem;
    color: var(--medium-gray);
    margin: 0;
}

/* Most Recent Area */
.most-recent-area .small-tittle h4,
.weekly2-news-area .small-tittle h4 {
    font-size: 1.2rem;
    font-weight: bold;
    border-bottom: 2px solid var(--main-yellow);
    padding-bottom: 5px;
    display: inline-block;
}
.most-recent-area .most-recent {
    position: relative;
}
.most-recent-area .most-recent-img img {
    width: 100%;
    height: 250px; /* Adjust height */
    object-fit: cover;
}
.most-recent-area .most-recent-cap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
    color: var(--white);
}
.most-recent-area .most-recent-cap span {
    display: inline-block;
    padding: 4px 8px;
    margin-bottom: 8px;
    font-size: 0.7rem;
    font-weight: bold;
    text-transform: uppercase;
    background-color: rgba(255, 0, 0, 0.7); /* Example: Vogue color */
}
.most-recent-area .most-recent-cap h4 {
    font-size: 1.1rem;
    margin-bottom: 5px;
    font-weight: bold;
}
.most-recent-area .most-recent-cap h4 a {
    color: var(--white);
}
.most-recent-area .most-recent-cap h4 a:hover {
    color: var(--main-yellow);
}
.most-recent-area .most-recent-cap p {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
}

.most-recent-area .most-recent-single {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.most-recent-area .most-recent-images {
    flex: 0 0 80px; /* Fixed width */
    margin-right: 15px;
}
.most-recent-area .most-recent-images img {
    width: 100%;
    height: 60px; /* Adjust height */
    object-fit: cover;
}
.most-recent-area .most-recent-capt {
    flex: 1;
}
.most-recent-area .most-recent-capt h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
    line-height: 1.3;
}
.most-recent-area .most-recent-capt h4 a {
    color: var(--dark-gray);
}
.most-recent-area .most-recent-capt h4 a:hover {
    color: var(--main-yellow);
}
.most-recent-area .most-recent-capt p {
    font-size: 0.8rem;
    color: var(--medium-gray);
    margin: 0;
}

/* --- Weekly News (Most Popular) --- */
.weekly2-news-area .home-banner2 img {
    width: 100%;
    height: auto; /* Adjust as needed */
}

.weekly2-news-area .weekly2-news-active {
    /* Styles for slider container if using one (e.g., Slick Slider, Swiper) */
    /* Example: Simple flex layout for now */
    display: flex;
    overflow-x: auto; /* Allow scrolling if items overflow */
    gap: 20px; /* Space between items */
    padding-bottom: 15px; /* Space for potential scrollbar */
}
.weekly2-news-area .weekly2-single {
    flex: 0 0 250px; /* Set width for each item */
    border: 1px solid #eee;
}
.weekly2-news-area .weekly2-img img {
    width: 100%;
    height: 180px; /* Adjust height */
    object-fit: cover;
}
.weekly2-news-area .weekly2-caption {
    padding: 15px;
}
.weekly2-news-area .weekly2-caption h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
    line-height: 1.3;
}
.weekly2-news-area .weekly2-caption h4 a {
    color: var(--dark-gray);
}
.weekly2-news-area .weekly2-caption h4 a:hover {
    color: var(--main-yellow);
}
.weekly2-news-area .weekly2-caption p {
    font-size: 0.8rem;
    color: var(--medium-gray);
    margin: 0;
}

/* --- Recent Articles (Trending News) --- */
.recent-articles .section-tittle h3 {
    font-size: 1.8rem;
    font-weight: bold;
}
.recent-articles .recent-active {
    /* Styles for slider container if using one */
    /* Example: Simple flex layout */
    display: flex;
    overflow-x: auto;
    gap: 30px;
    padding-bottom: 15px;
}
.recent-articles .single-recent {
    flex: 0 0 320px; /* Width for each item */
    position: relative; /* For play button */
    overflow: hidden;
}
.recent-articles .what-img img {
    width: 100%;
    height: 350px; /* Adjust height */
    object-fit: cover;
}
.recent-articles .what-cap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    color: var(--white);
}
.recent-articles .what-cap h4 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;
}
.recent-articles .what-cap h4 a {
    color: var(--white);
}
.recent-articles .what-cap h4 a:hover {
    color: var(--main-yellow);
}
.recent-articles .what-cap p {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
}
/* Play button styling - requires icon font (like FontAwesome or Flaticon) */
.recent-articles .popup-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dark-gray);
    font-size: 1.5rem;
    transition: all 0.3s ease;
    opacity: 0.8;
}
.recent-articles .popup-video:hover {
    background-color: var(--main-yellow);
    color: var(--white);
    opacity: 1;
}
.recent-articles .flaticon-play-button::before { /* Example using pseudo-element */
    content: '\25B6'; /* Unicode play symbol */
    display: block;
    line-height: 1;
}


/* --- Video Area --- */
.youtube-area {
    /* padding is already defined */
}
.youtube-area .main-video {
    /* Styles for the main video player container */
}
.youtube-area .main-video iframe,
.youtube-area .main-video video {
    border: 1px solid #ddd; /* Add a subtle border */
    margin-bottom: 10px;
}
.youtube-area .main-video h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 10px;
}
.youtube-area .video-thumbnails {
    margin-top: 30px;
}
.youtube-area .thumbnail-video {
    border: 1px solid #eee;
    background-color: var(--white);
    padding: 5px;
    transition: box-shadow 0.3s ease;
}
.youtube-area .thumbnail-video img,
.youtube-area .thumbnail-video video {
    margin-bottom: 8px;
    display: block;
    width: 100%;
    height: 100px; /* Thumbnail height */
    object-fit: cover;
}
.youtube-area .thumbnail-video h4.thumbnail-title {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.3;
    margin: 0;
    color: var(--dark-gray);
    /* Limit text lines */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2.6em; /* Approx height for 2 lines */
}

.youtube-area .thumbnail-video:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
.youtube-area .thumbnail-video.active {
     border: 2px solid var(--main-yellow);
}


/* --- Weekly News 3 --- */
.weekly3-news-area {
    /* Similar styles to WeeklyNews2 if layout is same */
}
.weekly3-news-area .weekly3-news-active {
     display: flex;
    overflow-x: auto;
    gap: 30px;
    padding-bottom: 15px;
}
.weekly3-news-area .weekly3-single {
     flex: 0 0 260px; /* Adjust width */
}
.weekly3-news-area .weekly3-img img {
    width: 100%;
    height: 200px; /* Adjust height */
    object-fit: cover;
}
.weekly3-news-area .weekly3-caption {
    padding: 15px;
    background-color: var(--white); /* Add background if needed */
    border: 1px solid #eee;
    border-top: none;
}
.weekly3-news-area .weekly3-caption h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
    line-height: 1.3;
}
.weekly3-news-area .weekly3-caption h4 a {
    color: var(--dark-gray);
}
.weekly3-news-area .weekly3-caption h4 a:hover {
    color: var(--main-yellow);
}
.weekly3-news-area .weekly3-caption p {
    font-size: 0.8rem;
    color: var(--medium-gray);
    margin: 0;
}

/* --- Banner Last --- */
.banner-area {
    /* Padding already defined */
}
.banner-area .banner-one img {
    width: 100%;
    height: 550px;
}

/* --- News Section (fh5co styles) --- */
.fh5co_heading {
  font-size: 1.5rem; /* Adjusted size */
  color: var(--dark-gray);
  font-weight: bold; /* Added weight */
}

.fh5co_heading_border_bottom {
  border-bottom: 2px solid var(--medium-gray); /* Thicker border */
  padding-bottom: 8px;
  margin-bottom: 25px; /* Increased margin */
}

.fh5co_hover_news_img .fh5co_news_img {
  height: 200px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  top: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
}

.fh5co_hover_news_img .fh5co_news_img > img {
  min-height: 100%; /* Ensure image covers */
  width: 100%;
  object-fit: cover;
}

.fh5co_hover_news_img:hover .fh5co_news_img {
  top: -5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.fh5co_magna {
  font-size: 1.1rem; /* Adjusted size */
  color: var(--dark-gray) !important;
  font-weight: 700; /* Bold */
  line-height: 1.4;
  display: block; /* Ensure it takes block space */
  margin-bottom: 5px;
}
.fh5co_magna:hover {
    color: var(--main-yellow) !important;
}

.fh5co_mini_time {
  font-size: 0.85rem;
  color: var(--medium-gray);
  display: block; /* Block display */
  margin-bottom: 10px;
}

.fh5co_consectetur {
  font-size: 0.95rem;
  color: var(--medium-gray);
  line-height: 1.6;
}

/* Tags */
.fh5co_tags_all {
    margin-bottom: 25px;
}
.fh5co_tagg {
  background: var(--light-gray);
  padding: 8px 12px; /* Adjusted padding */
  color: var(--dark-gray);
  border-radius: 3px; /* Slight rounding */
  transition: all 0.3s ease;
  margin-right: 6px;
  margin-bottom: 8px; /* Spacing */
  display: inline-block;
  font-size: 0.85rem;
  font-weight: 500;
}

.fh5co_tagg:hover,
.fh5co_tagg:focus {
  background: var(--main-yellow);
  color: var(--white);
  text-decoration: none;
}

/* Most Popular Sidebar */
.fh5co_most_trading {
  height: 65px; /* Adjusted */
  width: 100%;
  object-fit: cover;
  border-radius: 3px;
}

.most_fh5co_treding_font {
    font-size: 0.95rem;
    font-weight: 600;
    color: var(--dark-gray);
    line-height: 1.3;
    margin-bottom: 3px;
}
.most_fh5co_treding_font a { color: var(--dark-gray); }
.most_fh5co_treding_font a:hover { color: var(--main-yellow); }

.most_fh5co_treding_font_123 {
  font-size: 0.8rem;
  color: var(--medium-gray);
}

/* Pagination Placeholder */
.btn_mange_pagging, .btn_pagging {
  background: var(--light-gray);
  padding: 10px 18px;
  color: var(--dark-gray) !important;
  font-weight: 600;
  margin: 0 5px; /* Reduced margin */
  transition: all 0.3s ease;
  border-radius: 3px;
  border: 1px solid #ddd;
}

.btn_mange_pagging:hover,
.btn_mange_pagging:focus,
.btn_pagging:hover,
.btn_pagging:focus {
  background: var(--dark-gray);
  color: var(--white) !important;
  border-color: var(--dark-gray);
}


/* --- Weather News (using fh5co styles) --- */
/* Uses existing styles like fh5co_heading, fh5co_news_img, etc. */
/* Optional: Add specific container styles if needed */
.weather-news-container {
    background-color: #e9f5ff; /* Light blue background */
    padding-top: 30px;
    padding-bottom: 30px;
}

/* --- Placeholders --- */
.placeholder {
    padding: 20px;
    text-align: center;
    color: var(--medium-gray);
    font-style: italic;
    border: 1px dashed #ccc;
    background-color: #fafafa;
    margin-bottom: 20px; /* Add margin */
}
.loading-container, .error-container {
    padding: 50px 20px;
    text-align: center;
    font-size: 1.2rem;
}
.loading-container { color: var(--medium-gray); }
.error-container { color: #dc3545; font-weight: bold; }


/* --- Responsive Adjustments --- */

@media (max-width: 991px) { /* Medium devices (tablets, less than 992px) */
    .trending-area .trend-top-img img { height: 350px; }
    .trending-area .col-lg-4 .trend-top-img img { height: 180px; }
    .whats-news-area .properties__button .nav-tabs { justify-content: flex-start; }
    .whats-news-area .properties__button .nav-link { padding: 8px 10px; font-size: 0.9rem; }
    .whats-news-area .whats-news-single .whates-img img { height: 280px; }
    .weekly2-news-area .weekly2-single { flex: 0 0 220px; } /* Slightly smaller */
    .recent-articles .single-recent { flex: 0 0 240px; }
    .recent-articles .what-img img { height: 300px; }
    .youtube-area .thumbnail-video img, .youtube-area .thumbnail-video video { height: 80px; }
    .youtube-area .main-video iframe, .youtube-area .main-video video { height: 350px; }
    .fh5co_heading { font-size: 1.3rem; }
    .fh5co_magna { font-size: 1rem; }
}

@media (max-width: 767px) { /* Small devices (landscape phones, less than 768px) */
    .trending-area .trend-top-img img { height: 300px; }
    .trending-area .trend-top-cap h2 { font-size: 1.3rem; }
    .trending-area .trend-top-cap p { font-size: 0.8rem; }
    .trending-area .col-lg-4 .trend-top-img img { height: 200px; } /* Make side items taller again */
    .whats-news-area .section-tittle h3 { font-size: 1.5rem; }
    .whats-news-area .properties__button .nav-tabs { flex-wrap: wrap; } /* Allow tabs to wrap */
    .whats-news-area .properties__button .nav-link { margin-bottom: 5px; }
    .whats-news-area .whats-news-single .whates-img img { height: 250px; }
    .whats-news-area .whats-right-single { flex-direction: column; } /* Stack right side items */
    .whats-news-area .whats-right-img { margin-right: 0; margin-bottom: 10px; flex-basis: auto; width: 100%; }
    .whats-news-area .whats-right-img img { height: 150px; } /* Larger image when stacked */
    .weekly2-news-area .weekly2-single { flex: 0 0 200px; }
    .recent-articles .single-recent { flex: 0 0 220px; }
    .recent-articles .what-img img { height: 280px; }
    .youtube-area .main-video iframe, .youtube-area .main-video video { height: 300px; }
    .fh5co_news_img { height: 180px; }
    .fh5co_consectetur { font-size: 0.9rem; }
}

@media (max-width: 575px) { /* Extra small devices (portrait phones, less than 576px) */
    .trending-area .trend-top-img img { height: 250px; }
    .trending-area .trend-top-cap { padding: 15px; }
    .trending-area .trend-top-cap h2 { font-size: 1.1rem; }
    .trending-area .col-lg-4 { margin-top: 20px; } /* Add space between main and side */
    .whats-news-area .properties__button .nav-link { font-size: 0.8rem; padding: 6px 8px;}
    .whats-news-area .whats-news-single .whates-img img { height: 200px; }
    .whats-news-area .whates-caption h4 { font-size: 1.2rem; }
    .weekly2-news-area .weekly2-single { flex: 0 0 180px; }
    .weekly2-news-area .weekly2-caption h4 { font-size: 0.9rem; }
    .recent-articles .single-recent { flex: 0 0 200px; }
    .recent-articles .what-img img { height: 250px; }
    .recent-articles .what-cap h4 { font-size: 1rem; }
    .youtube-area .main-video iframe, .youtube-area .main-video video { height: 250px; }
    .youtube-area .thumbnail-video img, .youtube-area .thumbnail-video video { height: 70px; }
    .youtube-area .thumbnail-video h4.thumbnail-title { font-size: 0.8rem; }
    .fh5co_heading { font-size: 1.2rem; }
    .fh5co_hover_news_img .fh5co_news_img { height: 160px; }
    .col-md-8, .col-md-3 { padding-left: 10px; padding-right: 10px; } /* Reduce padding */
    .most_fh5co_treding_font { font-size: 0.9rem; }
    .btn_mange_pagging, .btn_pagging { padding: 8px 12px; font-size: 0.9rem;}
}
.poll-container {
    border-radius: 8px;
    padding: 15px;
    max-width: 350px;
    margin: auto;
}

.poll-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
}

.poll-question {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.poll-options-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.poll-option {
    margin-bottom: 10px;
}

.option-header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
}

.option-text {
    color: #007bff;
}

.option-percentage {
    color: #333;
}

.progress-bar {
    width: 100%;
    height: 8px;
    background: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 5px;
}

.progress-fill {
    height: 100%;
    background: #007bff;
}

.total-votes {
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
}
