/* AddPolls.css */

.add-polls-container {
    width: 80%;
    margin: 20px auto;
    font-family: Arial, sans-serif;
}

.poll-form {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    text-align: justify;
}

.poll-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.poll-form input[type="text"],
.poll-form textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
    font-size: 14px;
}

.poll-form textarea {
    resize: vertical; /* Allows the user to adjust the height of the textarea */
}

.poll-form button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.poll-form button:hover {
    background-color: #45a049;
}

.polls-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15px;
}

.polls-table th,
.polls-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.polls-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.polls-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.polls-table button {
    background-color: #3c8dbc;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.polls-table button:hover {
    background-color: #5baedf;
}

.error {
    color: red;
    margin-top: 10px;
}
.containerrs{
    margin-bottom: 50px;
}