/* AdminFooter.css */

.admin-footer {
    position: fixed; /* Make the footer fixed */
    bottom: 0; /* Stick it to the bottom */
    left: 0; /* Stick it to the left */
    width: 100%; /* Make it full width */
    text-align: center;
    padding: 10px;
    font-size: 1.0em;
    color: #ffffff;
    background: #3c8dbc;
    /* margin-top: 10px;  Remove this line */
    font-weight: 600;
    z-index: 1000; /* Ensure it's above other content, if needed */
  }
  .admin-copyright {
    margin-bottom: 5px;
  }
  
  .admin-design-credit {
    /* Optional: Add some style to the "Designed by" line */
  }
  
  .admin-dheer-link {
    color: #ffffff;
    text-decoration: none;
    font-weight: bolder;
  }
  
  .admin-dheer-link:hover {
    text-decoration: underline;
  }
  
  /* Media queries for responsiveness */
  
  /* Small screens (phones) */
  @media (max-width: 576px) {
    .admin-footer {
      font-size: 0.8em; /*  Smaller font size on smaller screens */
      padding: 8px; /*  Adjust padding */
      font-size: 0.755em;
    }
  
    .admin-copyright {
      margin-bottom: 3px; /* Less margin on small screens */
    }
  }
  
  /* Medium screens (tablets) */
  @media (min-width: 577px) and (max-width: 992px) {
    .admin-footer {
      font-size: 0.9em; /*  Slightly smaller font size on tablets */
      padding: 10px;
    }
  }
  
  /* Large screens (desktops) - You might not need this, but including for completeness */
  @media (min-width: 993px) {
    .admin-footer {
      font-size: 1.0em; /*  Back to standard on larger screens */
      padding: 10px;
    }
  }

