.layout {
    display: flex;
  }
  .navbar {
    background: #3c8dbc;   height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
  }
  
  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
    position: relative;
  }
  
  .nav-logo {
    color: #ffffff;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
  
    display: flex;
    align-items: center;
    justify-items: center;
  }
  
  .nav-logo .icon {
    margin-left: 45px;
    height: 140px;
    width: 160px;
    transition: transform 0.3s ease;
    position: relative;
    top: 30px;
    border-radius: 10px;
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-top: -7px;
  }
  
  .nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  /* .fa-code {
    margin-left: 1rem;
  } */
  
  .nav-item {
    line-height: 40px;
    margin-right: 1rem;
  }
  
  .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .nav-item:hover:after {
    width: 100%;
    background: #001c35;
  }
  
  .nav-item .active {
    color: white;
    border: 1px solid #ffffff;
  }
  
  .nav-icon {
    display: none;
    color: #2f5679;
  }
  
  .nav-icon .icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
  }
  
  @media screen and (max-width: 960px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1pxsolid #fff;
      position: absolute;
      top: 80px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #3470a5;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-item .active {
      color: black;
      border: none;
    }
    .nav-links {
      padding: 1.5rem;
      width: 100%;
      display: table;
    }
  
    .nav-logo .icon {
      width: 5.5rem;
      height: 6rem;
    }
  
    .nav-icon {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-100%, -50%);
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      color:white;
    }
  }
  .sidebar {
    background:#3c8dbc ;    color: white;
    width: 60px;
    transition: width 0.3s;
    overflow: hidden;
    height: 100vh;
    position: fixed;
  }
  
  .sidebar.open {
    width: 200px;
    z-index: 10; /* Higher z-index so navbar stays on top */
  }
  
  .toggle-btn {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    margin-top: 50px;
  }
  
  .menu {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .menu-item {
    display: flex;
    align-items: center;
    padding: 6px;
    color: white;
    text-decoration: none;
    transition: background 0.3s;
  }
  
  .menu-item:hover {
    background-color: #3c8dbc;
  }
  
  .menu-item .icon {
    font-size: 18px;
    /* margin-right: 10px; */
  }
  
  .menu-item .text {
    white-space: nowrap;
    font-size: 16px;
    margin: 10px;
  }
  .activee {
    background-color: #1e4561;
  }


/* Dashboard.css */

.menu-item.has-dropdown {
  position: relative;  /* Required for absolute positioning of the dropdown */
  cursor: pointer; /* Indicate it's clickable */
  display: flex;
  align-items: center; /* Vertically center the icon and text */
}

.dropdown-menu {
  position: absolute;
  top: 100%; /* Position the dropdown below the menu item */
  left: 0;
  background-color: #f9f9f9; /* Or your preferred background color */
  border: 1px solid #ccc; /* Or your preferred border */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow */
  z-index: 10; /* Ensure it's above other elements */
  width: 100%; /* Match the width of the menu item */
  display: none; /* Initially hidden */
}

.menu-item.has-dropdown:hover .dropdown-menu,
.menu-item.has-dropdown:focus .dropdown-menu,
.menu-item.has-dropdown:active .dropdown-menu {
  display: block; /* Show on hover/focus/click of the parent menu item */
}

.dropdown-item {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: #ffffff;
}

.dropdown-item:hover {
  background-color: #464646;
}

.dropdown-item.active {
  background-color: #007bff; /* Example active color */
  color: white;
}
/* ========================================== */
/*        DROPDOWN MENU CSS (CLICK-BASED)     */
/* ========================================== */

.dropdown-menu {
  /* Default State: Hidden & Ready for Transition */
  max-height: 0; /* Collapse the element vertically */
  overflow: hidden; /* IMPORTANT: Hide content that overflows the 0 height */
  padding-top: 0; /* Collapse padding */
  padding-bottom: 0; /* Collapse padding */
  margin: 0;
  list-style: none;
  background-color: rgba(0, 0, 0, 0.1); /* Optional: Slightly different background */

  /* Define the transition ON THE BASE CLASS */
  transition: max-height 0.3s ease-out,
              padding-top 0.3s ease-out,
              padding-bottom 0.3s ease-out;

  /* CRITICAL: Ensure NO 'display: none;' rule is active here */
}

/* State when 'open' class is added by React */
.dropdown-menu.open {
  max-height: 500px; /* SET A VALUE LARGER THAN THE TALLEST DROPDOWN */
                     /* Allows the element to expand */
  padding-top: 5px; /* Add back padding when open */
  padding-bottom: 5px;
  overflow: visible; /* Optional: Can sometimes help if content inside needs to overflow slightly, but usually not needed with sufficient max-height */
}

/* Styling for the links inside the dropdown */
.dropdown-item {
  display: block;
  padding: 8px 15px 8px 40px; /* Indented */
  color: #f0f0f0;
  text-decoration: none;
  font-size: 0.9em;
  white-space: nowrap;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.dropdown-item.active {
  color: white;
  font-weight: bold;
}

/* Arrow styling */
.dropdown-arrow {
  margin-left: auto;
  font-size: 0.8em;
  transition: transform 0.3s ease;
  display: inline-block;
  opacity: 0;
}
.sidebar.open .dropdown-arrow {
    opacity: 1;
}
.dropdown-arrow.open {
    transform: rotate(180deg);
}

/* Ensure parent menu items allow clicks */
 .menu-item.has-dropdown {
    cursor: pointer;
 }

/* ========================================== */
/*    END DROPDOWN MENU CSS                   */
/* ========================================== */

/* --- Make sure your other styles (.sidebar, .content, etc.) are present --- */