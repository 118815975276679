/* --- Font Awesome (Ensure it's loaded) --- */
/* @import url('...'); */

/* --- CSS Variables --- */
:root {
  --main-yellow: #fcb900;
  --yellow-dark: #e0a800;
  --dark-gray: #212529; /* Standard dark */
  --medium-gray: #6c757d;
  --light-gray: #f8f9fa;
  --border-color: #e9ecef; /* Lighter border */
  --white: #fff;
  --shadow-soft: 0 4px 15px rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 8px 25px rgba(0, 0, 0, 0.1);
  --navbar-height: 80px; /* Slightly taller */
  --navbar-sticky-height: 70px;
  --top-bar-height: 65px;
  --font-primary: 'Poppins', sans-serif;
  --font-secondary: 'Lato', sans-serif;
  --transition-speed: 0.3s;
  --transition-easing: ease-in-out;
}

/* --- Global Body Class for Mobile Menu Active --- */
body.newsnav-mobile-menu-active {
  overflow: hidden; /* Prevent scrolling background when mobile menu is open */
}

/* --- Base Header Styling --- */
.newsnav-header {
  width: 100%;
  z-index: 1000; /* Ensure it's above most content */
  background-color: var(--white);
  transition: top var(--transition-speed) var(--transition-easing), box-shadow var(--transition-speed) var(--transition-easing);
  position: relative;
  top: 0;
  box-shadow: var(--shadow-soft); /* Subtle shadow always */
}

.newsnav-header.newsnav-sticky {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.97); /* High opacity white */
  box-shadow: var(--shadow-medium); /* Stronger shadow when sticky */
  backdrop-filter: blur(8px); /* Increase blur */
  animation: slideDownNav 0.4s ease-out;
}

@keyframes slideDownNav {
  from { transform: translateY(-100%); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* --- Top Bar --- */
.newsnav-top-bar {
  background-color: var(--dark-gray);
  color: rgba(255, 255, 255, 0.7); /* Lighter text */
  padding: 0; /* Remove padding, control height directly */
  height: var(--top-bar-height);
  font-size: 0.8rem; /* Smaller font */
  font-family: var(--font-secondary);
  line-height: var(--top-bar-height); /* Vertically center text */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Subtle separator */
}
/* === Top Navigation Bar === */
.newsnav-top-bar {
  background-color: #222; /* Dark background */
  color: #ccc;           /* Light text color */
  padding: 8px 0;        /* Vertical padding */
  font-size: 0.85rem;    /* Slightly smaller font */
  border-bottom: 1px solid #444; /* Subtle border */
}

/* Container for top bar content (assuming .container handles max-width/centering) */
.newsnav-top-content {
  display: flex;             /* Use flexbox for alignment */
  justify-content: space-between; /* Pushes date left, social/user right */
  align-items: center;       /* Vertically center items */
  flex-wrap: wrap;           /* Allow wrapping on very small screens */
  gap: 15px;                 /* Add gap for wrapping case */
}

/* Date/Time section */
.newsnav-date-time {
  /* Styles are mostly inherited */
  flex-shrink: 0; /* Prevent shrinking too much */
}

.newsnav-date-time span {
  /* Inherits color */
}

/* Container for social icons and user icon */
.newsnav-social-user {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between social icons group and user icon */
}

/* Container specifically for the social icons */
.newsnav-social-icons {
  display: flex;
  align-items: center;
  gap: 25px; /* Space between individual social icons */
}

/* Individual social icon links */
.newsnav-social-icons a {
  color: #ccc; /* Inherited, but can be explicit */
  text-decoration: none;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out; /* Smooth transitions */
  display: inline-block; /* Needed for transform */
}

.newsnav-social-icons a:hover {
  color: #fff;           /* Brighter color on hover */
  transform: scale(1.1); /* Slight scale effect on hover */
}

/* Icon elements within links */
.newsnav-social-icons a i {
  font-size: 1rem;      /* Adjust icon size as needed */
  vertical-align: middle; /* Helps alignment */
}

/* User icon container */
.newsnav-user-icon {
  color: #ccc;
  cursor: pointer;       /* Indicate it's clickable */
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
  display: inline-block;
}

.newsnav-user-icon:hover {
  color: #fff;
  transform: scale(1.1);
}

/* User icon element */
.newsnav-user-icon i {
  font-size: 1.1rem;    /* Slightly larger user icon */
  vertical-align: middle;
}

/* --- Responsiveness (Example for smaller screens) --- */
@media (max-width: 768px) {
  .newsnav-top-content {
      justify-content: center; /* Center content on smaller screens */
      gap: 10px; /* Reduce gap */
  }

  .newsnav-date-time {
      /* Optionally hide the date on small screens if too cluttered */
       display: none;
      /* Or just reduce font size */
      /* font-size: 0.8rem; */
  }

  .newsnav-social-user {
     gap: 15px; /* Reduce gap */
  }

  .newsnav-social-icons {
     gap: 26px; /* Reduce gap */
  }
}

@media (max-width: 480px) {
  .newsnav-top-bar {
      font-size: 0.8rem;
      padding: 6px 0;
  }
   .newsnav-social-icons a i {
      font-size: 0.9rem;
  }
   .newsnav-user-icon i {
      font-size: 1rem;
  }
}
.newsnav-top-bar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.newsnav-top-left span {
  opacity: 0.8;
}

/* Example Live Indicator */
/* .newsnav-live-indicator {
  background-color: #dc3545;
  color: white;
  padding: 2px 8px;
  border-radius: 3px;
  font-weight: bold;
  font-size: 0.7rem;
  margin-right: 10px;
  vertical-align: middle;
} */


.newsnav-top-right {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 50px;
}

.newsnav-social {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  height: 100%; /* Ensure alignment */
}

.newsnav-social li {
  margin-left: 18px; /* More spacing */
}

.newsnav-social li a {
  color: rgba(255, 255, 255, 0.6); /* Dimmer icons */
  font-size: 0.95rem;
  transition: color var(--transition-speed) ease, transform var(--transition-speed) ease;
  display: inline-block; /* Needed for transform */
}

.newsnav-social li a:hover {
  color: var(--main-yellow);
  transform: translateY(-2px); /* Subtle lift */
}

.newsnav-user-icon {
  margin-left: 30px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.4rem; /* Larger icon */
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
  line-height: 1; /* Prevent extra space */
  padding: 5px; /* Add padding for easier click */
}

.newsnav-user-icon:hover {
  color: var(--main-yellow);
  transform: scale(1.1);
}

/* --- Main Navigation --- */
.newsnav-main {
  border-bottom: 1px solid var(--border-color);
}

.newsnav-main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--navbar-height);
  transition: height var(--transition-speed) var(--transition-easing);
}

.newsnav-sticky .newsnav-main-content {
  height: var(--navbar-sticky-height);
}

/* Logo */
.newsnav-logo {
  flex-shrink: 0;
  padding-right: 30px; /* Space between logo and menu */
}

.newsnav-logo img {
  max-height: 220px; /* Adjust */
  width: auto;
  transition: max-height var(--transition-speed) var(--transition-easing);
}

.newsnav-sticky .newsnav-logo img {
  max-height: 170px;
}

/* Desktop Navigation Menu */
.newsnav-menu-desktop {
  display: flex;
  transition: opacity 0.3s ease; /* For mobile fade out */
}
.newsnav-menu-desktop.mobile-fade-out { /* Class added when mobile menu opens */
  opacity: 0;
  pointer-events: none;
}


.newsnav-navigation {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.newsnav-navigation li {
  margin-left: 8px; /* Spacing between links */
  position: relative;
}

.newsnav-navigation li a {
  display: block;
  padding: 12px 20px; /* Adjust padding for desired size */
  color: var(--dark-gray);
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 1.05rem; /* Slightly larger */
  text-decoration: none;
  text-transform: capitalize;
  transition: color var(--transition-speed) ease, background-color var(--transition-speed) ease;
  border-radius: 6px; /* Rounded pill effect */
  position: relative;
  overflow: hidden; /* For background effect */
  z-index: 1;
  white-space: nowrap;
}

/* Elegant Hover/Active Effect - Background Fill */
.newsnav-navigation li a::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: var(--main-yellow);
  opacity: 0.15; /* Subtle background */
  z-index: -1;
  border-radius: 6px;
  transition: height var(--transition-speed) var(--transition-easing);
}

.newsnav-navigation li a:hover::before,
.newsnav-navigation li a.active::before {
  height: 100%; /* Fill background */
}

.newsnav-navigation li a:hover,
.newsnav-navigation li a.active {
  color: var(--dark-gray); /* Keep text dark for contrast on light yellow */
}

/* Optional: Add border bottom to active link for more emphasis */
/* .newsnav-navigation li a.active {
  border-bottom: 3px solid var(--main-yellow);
  padding-bottom: 9px; // Adjust padding to account for border
} */


/* Dropdown Menu */
.newsnav-dropdown .newsnav-dropdown-icon {
  font-size: 0.65em; /* Smaller chevron */
  margin-left: 6px;
  transition: transform var(--transition-speed) ease;
  display: inline-block; /* Prevent shifting layout */
  vertical-align: middle;
}

.newsnav-dropdown:hover .newsnav-dropdown-icon {
  transform: rotate(180deg);
}

.newsnav-submenu {
  display: block; /* Keep block for animation */
  position: absolute;
  top: calc(100% + 5px); /* Add small gap */
  left: 50%; /* Center align */
  transform: translateX(-50%) translateY(10px); /* Initial position for animation */
  background-color: var(--white);
  min-width: 220px; /* Wider dropdown */
  list-style: none;
  padding: 10px 0;
  margin: 0;
  border: 1px solid var(--border-color);
  border-radius: 8px; /* More rounded */
  box-shadow: var(--shadow-medium);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease, transform 0.25s ease, visibility 0.25s;
  z-index: 10; /* Ensure dropdown is above content */
}

.newsnav-dropdown:hover > .newsnav-submenu {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0); /* Slide up */
}

.newsnav-submenu li {
  margin-left: 0;
}

.newsnav-submenu li a {
  padding: 12px 25px; /* More padding */
  font-size: 0.98rem; /* Slightly larger text */
  font-weight: 500;
  color: var(--medium-gray); /* Softer text color */
  border-radius: 0;
  white-space: nowrap;
  transition: background-color 0.2s ease, color 0.2s ease, padding-left 0.2s ease;
}
.newsnav-submenu li a::before { display: none; } /* No background effect here */

.newsnav-submenu li a:hover,
.newsnav-submenu li a.active {
  background-color: var(--main-yellow);
  color: var(--dark-gray);
  padding-left: 30px; /* Indent on hover */
}


/* --- Mobile Menu --- */
.newsnav-mobile-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-left: auto; /* Push to the right */
  z-index: 1011; /* Above overlay */
  position: relative; /* Ensure it's clickable */
}

.newsnav-mobile-toggle span {
  display: block;
  width: 28px; /* Slightly wider bars */
  height: 3px;
  background-color: var(--dark-gray);
  margin: 6px 0; /* More space */
  border-radius: 3px;
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275); /* Bouncy transition */
}

/* Hamburger animation */
.newsnav-mobile-toggle.open span:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}
.newsnav-mobile-toggle.open span:nth-child(2) {
  transform: scaleX(0); /* Vanish middle bar */
  opacity: 0;
}
.newsnav-mobile-toggle.open span:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

/* Mobile Menu Panel */
.newsnav-mobile-menu {
  display: block; /* Always block for transition */
  position: fixed; /* Use fixed for full height */
  top: 0;
  right: -100%; /* Start off-screen */
  width: 300px; /* Adjust width */
  max-width: 80%;
  height: 100vh;
  background-color: var(--dark-gray); /* Dark background */
  box-shadow: -5px 0 20px rgba(0, 0, 0, 0.2);
  padding: calc(var(--navbar-height) + 20px) 0 30px 0; /* Padding below header */
  transition: right 0.4s cubic-bezier(0.23, 1, 0.32, 1); /* Smooth ease out */
  z-index: 1010; /* Above content, below toggle */
  overflow-y: auto;
}
.newsnav-sticky .newsnav-mobile-menu {
   padding-top: calc(var(--navbar-sticky-height) + 20px);
}


.newsnav-mobile-menu.open {
  right: 0; /* Slide in */
}

.newsnav-mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.newsnav-mobile-menu ul li a {
  display: block;
  padding: 15px 30px; /* Generous padding */
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8); /* Light text */
  font-family: var(--font-primary);
  font-weight: 500;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Subtle separator */
  transition: background-color 0.2s ease, color 0.2s ease, padding-left 0.2s ease;
}
.newsnav-mobile-menu ul li:last-child a {
  border-bottom: none;
}

.newsnav-mobile-menu ul li a:hover,
.newsnav-mobile-menu ul li a.active {
  background-color: var(--main-yellow);
  color: var(--dark-gray);
  padding-left: 35px; /* Indent on select */
}

/* Mobile Extras */
.newsnav-mobile-extras {
  padding: 25px 20px;
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.newsnav-mobile-extras .newsnav-user-icon {
  display: block;
  margin-bottom: 25px;
  font-size: 1.1rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.2s ease;
}
.newsnav-mobile-extras .newsnav-user-icon:hover {
  color: var(--main-yellow);
}
.newsnav-mobile-extras .newsnav-user-icon i {
  margin-right: 10px;
  color: var(--main-yellow);
  font-size: 1.3rem;
  vertical-align: middle;
}
.newsnav-mobile-extras .newsnav-social {
  justify-content: flex-start; /* Align left */
}
.newsnav-mobile-extras .newsnav-social li {
  margin: 0 12px 0 0; /* Right margin only */
}
.newsnav-mobile-extras .newsnav-social li a {
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.6);
}
.newsnav-mobile-extras .newsnav-social li a:hover {
  color: var(--main-yellow);
}

/* Mobile Menu Overlay */
.newsnav-mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1009; /* Below mobile menu, above content */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, visibility 0.4s;
}

body.newsnav-mobile-menu-active .newsnav-mobile-overlay {
  opacity: 1;
  visibility: visible;
}


/* --- Reporter Popup Enhancements --- */
.newsnav-user-popup {
  /* Base styles remain */
}

.newsnav-user-popup-content {
  /* Base styles remain */
}

/* Loading Spinner */
.newsnav-spinner {
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: var(--dark-gray);
  border-radius: 50%;
  animation: newsnav-spin 0.8s linear infinite;
  vertical-align: middle;
}

@keyframes newsnav-spin {
  to { transform: rotate(360deg); }
}

.newsnav-popup-message {
  text-align: center;
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 0.95rem;
  padding: 10px 15px;
  border-radius: 6px;
  font-weight: 500;
}
.newsnav-popup-message.error {
  color: #842029;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
}
.newsnav-popup-message.success { /* If you add success messages */
  color: #0f5132;
  background-color: #d1e7dd;
  border: 1px solid #badbcc;
}

.newsnav-user-popup-content .user-data {
  /* Base styles remain */
}

.newsnav-user-popup-content .user-data img {
   /* Base styles remain */
   border: 4px solid var(--white); /* Thicker white border */
   box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15); /* Stronger shadow */
}

/* Grid layout for data */
.newsnav-user-popup-content .user-data-grid {
  display: grid;
  grid-template-columns: auto 1fr; /* Label column, Data column */
  gap: 8px 15px; /* Row gap, Column gap */
  align-items: center;
}

.newsnav-user-popup-content .user-data-grid span {
   font-family: var(--font-secondary);
   font-size: 1rem;
   color: #444;
   line-height: 1.5;
   word-break: break-word; /* Prevent long addresses from breaking layout */
}
.newsnav-user-popup-content .user-data-grid span strong {
   font-family: var(--font-primary);
   font-weight: 600;
   color: var(--dark-gray);
   margin-right: 0; /* Remove margin, grid handles spacing */
   display: inline; /* Override previous display */
}

/* --- Responsive Adjustments --- */
@media (max-width: 991px) { /* Tablets */
  .newsnav-mobile-toggle { display: block; }
  .newsnav-menu-desktop { display: none; }
  .newsnav-main-content { height: var(--navbar-height); } /* Keep consistent height */
  .newsnav-sticky .newsnav-main-content { height: var(--navbar-sticky-height); }
  .newsnav-logo img { max-height: 50px; }
  .newsnav-sticky .newsnav-logo img { max-height: 40px; }
  .newsnav-top-bar { height: auto; line-height: 1.5; padding: 5px 0;} /* Allow wrap */
   .newsnav-top-bar-content { flex-direction: column; }
   .newsnav-top-left { margin-bottom: 5px; }
}

/* Further adjustments for smaller screens as needed */
@media (max-width: 575px) {
  :root { /* Adjust heights for small screens */
      --navbar-height: 70px;
      --navbar-sticky-height: 60px;
      --top-bar-height: auto; /* Allow wrap */
  }
   .newsnav-logo img { max-height: 185px; }
   .newsnav-sticky .newsnav-logo img { max-height: 35px; }
   .newsnav-mobile-menu { width: 280px; } /* Slightly narrower */
   .newsnav-navigation li a { padding: 10px 15px; font-size: 1rem;} /* Adjust if needed */
   .newsnav-user-popup-content { padding: 25px 20px; }
   .newsnav-user-popup-content h3 { font-size: 1.4rem; }
   .newsnav-user-popup-content .user-data-grid { font-size: 0.95rem; gap: 5px 10px;}
}


/* User Popup Styling */
.user-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Slightly darker, softer overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px); /* Add a subtle blur to the background */
}

.user-popup-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px; /* More rounded corners */
  width: 450px; /* Slightly wider */
  max-width: 90%; /* Responsive width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Softer shadow */
  animation: fadeIn 0.3s ease-in-out; /* Subtle animation */
  position: relative;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}


.user-popup h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #3498db; /* A modern blue */
  text-align: center;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 28px; /* Larger close button */
  cursor: pointer;
  color: #777;
  transition: color 0.2s ease;
  background: none;
  border: none;
  padding: 0;
}

.close-button:hover {
  color: #e74c3c; /* Red on hover */
}

/* Input Group Styling */
.input-group {
  display: flex;
  margin-bottom: 25px;
  /* border: 1px solid #ddd;  */
  /* border-radius: 8px; */
  overflow: hidden; /* Hide overflow for rounded corners */
}

.input-group input {
  flex: 1;
  padding: 12px 15px;
  border: none;
  outline: none;
  font-size: 16px;
  color: #555;
}

.input-group button {
  padding: 12px 20px;
  background-color: #3498db; /* Consistent blue */
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.input-group button:hover {
  background-color: #2980b9; /* Darker blue on hover */
}

/* User Data Styling */
.user-data {
  margin-top: 30px;
  text-align: justify;
}

.user-data img {
  width: 150px;
  height: 150px;
  border-radius: 5%;
  object-fit: cover;
  margin-left: 107px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow on image */
  transition: transform 0.3s ease; /* Subtle zoom effect */
}

.user-data img:hover {
  transform: scale(1.05);
}

.user-data p {
  font-size: 18px;
  color: #555;
  margin-bottom: 8px;
}

.user-data p strong {
  font-weight: bold;
  color: #333;
  margin-right: 5px;
}
/* --- Keep all previous elegant Navbar styles --- */
/* --- ... (Variables, Header, Top Bar, Main Nav, Links, Mobile, Popup etc.) ... --- */


/* --- Dropdown Menu Positioning and Base Styles --- */
.newsnav-submenu {
  position: absolute;
  top: calc(100% + 8px); /* Slightly larger gap from parent link */
  /* --- KEY CHANGE: Align RIGHT edge instead of left --- */
  right: 0;            /* Align right edge to parent's right edge */
  left: auto;          /* Ensure left alignment is not interfering */
  /* --- END KEY CHANGE --- */

  background-color: var(--white);
  min-width: 220px; /* Default min-width for standard list dropdowns */
  max-width: calc(100vw - 30px); /* Prevent exceeding viewport width (minus padding) */
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid var(--border-color);
  border-top: 3px solid var(--main-yellow);
  border-radius: 8px; /* Rounded corners all around */
  box-shadow: var(--shadow-medium);
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px); /* Start slightly lower for animation */
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
  z-index: 1001;
}

/* Adjust positioning for dropdowns NOT near the edge if needed */
/* You might need JS for this, but simple CSS might work if nav items are predictable */
/* Example: If the parent LI is one of the first few items */
.newsnav-navigation li:nth-child(-n+3) .newsnav-submenu { /* Target first 3 items */
 /* right: auto; */ /* Uncomment if needed */
 /* left: 0; */   /* Uncomment if needed */
}


.newsnav-dropdown:hover > .newsnav-submenu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* --- GRID SUBMENU SPECIFIC STYLES --- */
.newsnav-submenu-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default 3 columns */
  gap: 6px; /* Increased gap */
  padding: 25px; /* Increased internal padding for more space */
  min-width: 600px; /* Adjusted minimum width */
  /* Positioning (right: 0) is inherited */
  /* max-width is inherited */
}

.newsnav-submenu-grid li {
  margin: 0;
  list-style: none;
}

.newsnav-submenu-grid li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px 12px; /* Adjust padding */
  font-size: 0.95rem; /* Adjust font */
  font-weight: 500;
  color: var(--dark-gray);
  background-color: var(--light-gray);
  border-radius: 8px; /* More rounded */
  text-align: center;
  text-decoration: none;
  line-height: 1.35;
  height: 85px; /* Adjust height */
  transition: all var(--transition-speed) var(--transition-easing);
  border: 1px solid transparent; /* Start with transparent border */
  overflow: hidden;
  position: relative;
}

.newsnav-submenu-grid li a:hover,
.newsnav-submenu-grid li a.active { /* Keep active state consistent */
  background-color: var(--main-yellow);
  color: var(--dark-gray);
  border-color: var(--yellow-dark);
  transform: translateY(-4px); /* More lift */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.12);
}

/* 'View All' Link Styling */
.newsnav-submenu-grid .newsnav-view-all-cats {
  grid-column: 1 / -1;
  margin-top: 15px; /* More space above */
}

.newsnav-submenu-grid .newsnav-view-all-cats a {
  display: block;
  background-color: var(--dark-gray);
  color: var(--white);
  text-align: center;
  padding: 14px; /* More padding */
  font-weight: 600;
  font-size: 0.95rem;
  border-radius: 8px;
  height: auto;
  transition: background-color var(--transition-speed) ease, color var(--transition-speed) ease;
  border: 1px solid var(--dark-gray);
}
.newsnav-submenu-grid .newsnav-view-all-cats a i {
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.newsnav-submenu-grid .newsnav-view-all-cats a:hover {
  background-color: var(--white); /* Change hover background */
  color: var(--dark-gray);
  border-color: var(--dark-gray);
  transform: none;
  box-shadow: none;
}
.newsnav-submenu-grid .newsnav-view-all-cats a:hover i {
  transform: translateX(5px);
}


/* --- Grid Submenu Responsiveness --- */
@media (max-width: 991px) { /* Tablets */
  .newsnav-submenu-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns */
      min-width: 450px; /* Adjust width */
      gap: 15px;
      padding: 20px;
  }
  .newsnav-submenu-grid li a {
      padding: 15px 10px;
      height: 80px;
      font-size: 0.9rem;
  }
}

@media (max-width: 767px) {
   /* Mobile menu handles navigation, dropdown hidden */
}

@media (max-width: 575px) {
  /* Adjustments for very small screens if the dropdown were visible */
  .newsnav-submenu-grid {
      grid-template-columns: repeat(2, 1fr); /* Can keep 2 cols */
      min-width: 90vw; /* Use viewport width */
      left: 50%; /* Center align fallback */
      right: auto;
      transform: translateX(-50%) translateY(10px); /* Center positioning */
      gap: 10px;
      padding: 15px;
  }
   .newsnav-dropdown:hover > .newsnav-submenu-grid {
      transform: translateX(-50%) translateY(0);
   }
  .newsnav-submenu-grid li a {
      height: 75px;
      font-size: 0.85rem;
  }
}

/* --- Ensure other styles are present --- */