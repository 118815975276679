/* --- Font Awesome (Ensure it's loaded) --- */
/* @import url('...'); */

/* --- CSS Variables --- */
:root {
  --main-yellow: #fcb900;
  --yellow-dark: #e0a800;
  --footer-bg: #1f2327; /* Slightly lighter dark */
  --footer-bg-lighter: #2a2e33; /* For form background */
  --footer-text: rgba(255, 255, 255, 0.75); /* Slightly brighter text */
  --footer-heading: #ffffff; /* Pure white headings */
  --footer-link-hover: var(--main-yellow);
  --footer-border: rgba(255, 255, 255, 0.15); /* More visible border */
  --footer-bottom-bg: #16191c; /* Darker bottom */
  --font-primary: 'Poppins', sans-serif;
  --font-secondary: 'Lato', sans-serif;
  --transition-speed: 0.3s;
  --transition-easing: ease-in-out;
}

/* --- Main Footer Wrapper --- */
.bestnews-footer {
  background-color: var(--footer-bg);
  color: var(--footer-text);
  padding: 80px 0 0 0; /* Increased top padding */
  font-family: var(--font-secondary);
  font-size: 1rem; /* Base size 16px */
  line-height: 1.8; /* More line spacing */
}
.bestnews-footer p{
  color: #ffffff;
}
/* --- Main Footer Content Grid --- */
.bestnews-footer-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr)); /* Adjust minmax */
  gap: 50px 40px; /* Increased gap */
  padding-bottom: 60px;
  border-bottom: 1px solid var(--footer-border);
}

/* --- Footer Columns --- */
.bestnews-footer-col {
  /* Base styles */
}

/* Footer Headings */
.bestnews-footer-heading {
  font-family: var(--font-primary);
  font-size: 1.35rem; /* Larger heading */
  font-weight: 600;
  color: var(--footer-heading);
  margin-bottom: 30px; /* More space below heading */
  position: relative;
  padding-bottom: 12px;
  letter-spacing: 0.5px;
}
.bestnews-footer-heading::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px; /* Wider underline */
  height: 4px; /* Thicker underline */
  background-color: var(--main-yellow);
  border-radius: 2px; /* Slightly rounded underline */
}

/* --- Styles for Columns 1, 2, 3 (Keep previous good styles) --- */

/* Column 1: About & Logo */
.bestnews-footer-about .bestnews-footer-logo img {
  max-width: 190px;
  margin-bottom: 25px;
  opacity: 1; /* Full opacity logo */
}
.bestnews-footer-description {
  margin-bottom: 30px;
  font-size: 0.98rem;
  opacity: 0.9; /* Slightly less emphasis */
  color: #ffffff;
}
.bestnews-contact-info p {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.bestnews-contact-info i {
  color: var(--main-yellow);
  margin-right: 15px;
  width: 20px;
  text-align: center;
  font-size: 1.15em;
  flex-shrink: 0; /* Prevent icon shrinking */
}
.bestnews-contact-info a {
  color: var(--footer-text);
  transition: color var(--transition-speed) ease;
}
.bestnews-contact-info a:hover {
  color: var(--footer-link-hover);
}

/* Columns 2 & 3: Links */
.bestnews-footer-links { }
.bestnews-footer-nav { list-style: none; padding: 0; margin: 0; }
.bestnews-footer-nav li { margin-bottom: 12px; } /* Slightly more space */
.bestnews-footer-nav li a {
  color: var(--footer-text);
  text-decoration: none;
  transition: color var(--transition-speed) ease, transform var(--transition-speed) ease;
  position: relative;
  display: inline-block;
}
/* Link hover effect: Subtle shift right */
.bestnews-footer-nav li a:hover {
  color: var(--footer-link-hover);
  transform: translateX(5px);
}
/* Optional: Add back arrow on hover if desired, adjust styling */
/* .bestnews-footer-nav li a::before { ... } */
/* .bestnews-footer-nav li a:hover { padding-left: 15px; } */
/* .bestnews-footer-nav li a:hover::before { opacity: 1; left: 0; } */


/* --- Column 4: Connect (Newsletter & Social) ENHANCED --- */
.bestnews-footer-connect {
  /* Styles for the container if needed */
}

/* Enhanced Newsletter Form */
.bestnews-newsletter-form {
  display: flex;
  margin-bottom: 40px; /* More space below form */
  border-radius: 8px; /* More rounding */
  background-color: var(--footer-bg-lighter); /* Distinct background */
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.2); /* Inner shadow */
  border: 1px solid rgba(255, 255, 255, 0.08); /* Subtle border */
}
.bestnews-newsletter-form input {
  flex-grow: 1;
  padding: 14px 18px; /* Increased padding */
  border: none;
  background: transparent;
  color: var(--white);
  font-size: 1rem;
  outline: none;
  font-family: var(--font-secondary);
}
.bestnews-newsletter-form input::placeholder {
  color: rgba(255, 255, 255, 0.4); /* Lighter placeholder */
  opacity: 1;
}
.bestnews-newsletter-form button {
  padding: 14px 25px;
  background-color: var(--main-yellow);
  color: var(--dark-gray);
  border: none;
  cursor: pointer;
  font-size: 0.95rem; /* Adjust size */
  font-weight: 600;
  font-family: var(--font-primary);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-radius: 0 8px 8px 0; /* Match form rounding */
  transition: background-color var(--transition-speed) ease, transform 0.2s ease;
}
.bestnews-newsletter-form button:hover {
  background-color: var(--yellow-dark);
  transform: scale(1.05); /* Slight scale effect */
}
.bestnews-newsletter-form button:active {
  transform: scale(1); /* Press effect */
}
.bestnews-newsletter-form button span { /* Style button text */
  display: inline-block;
}

/* Follow Us Heading */
.bestnews-follow-heading {
  margin-bottom: 20px; /* Space above icons */
  /* Keep heading styles from .bestnews-footer-heading */
}

/* Enhanced Social Links */
.bestnews-social-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Increased gap */
}
.bestnews-social-links li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px; /* Larger icons */
  height: 45px;
  background-color: var(--footer-bg-lighter); /* Use lighter bg */
  color: var(--footer-text);
  border-radius: 8px; /* Rounded square */
  font-size: 1.25rem; /* Larger icon glyph */
  text-decoration: none;
  border: 1px solid var(--footer-border); /* Add border */
  transition: all var(--transition-speed) var(--transition-easing);
  box-shadow: var(--shadow-soft);
}
.bestnews-social-links li a:hover {
  background-color: var(--main-yellow);
  color: var(--dark-gray);
  transform: translateY(-4px) scale(1.05); /* Lift and scale */
  border-color: var(--main-yellow);
  box-shadow: 0 6px 12px rgba(252, 185, 0, 0.2); /* Yellowish glow */
}

/* --- Footer Bottom Bar --- */
.bestnews-footer-bottom {
  background-color: var(--footer-bottom-bg);
  padding: 25px 0; /* More padding */
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.6); /* Slightly brighter */
}
.bestnews-footer-bottom-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}
.bestnews-copyright {
  margin: 0;
}
.bestnews-footer-bottom-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  margin-right: 78px;
}
.bestnews-footer-bottom-links li {
  margin-left: 25px; /* More spacing */
}
.bestnews-footer-bottom-links li a {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  transition: color var(--transition-speed) ease;
}
.bestnews-footer-bottom-links li a:hover {
  color: var(--main-yellow);
}

/* --- Responsiveness (Adjustments based on previous version) --- */

@media (max-width: 991px) { /* Keep large device breakpoint for 4 columns if content allows */
  .bestnews-footer-main {
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }
}

@media (max-width: 767px) { /* Small devices */
  .bestnews-footer {
      padding-top: 60px;
      text-align: center;
  }
  .bestnews-footer-main {
      grid-template-columns: 1fr; /* Stack to 1 column */
      gap: 35px;
      padding-bottom: 40px;
  }
  .bestnews-footer-heading::after {
      left: 50%;
      transform: translateX(-50%);
  }
  .bestnews-contact-info p {
      justify-content: center;
  }
  .bestnews-social-links {
      justify-content: center;
  }
  .bestnews-newsletter-form {
      max-width: 400px; /* Limit width on mobile */
      margin-left: auto;
      margin-right: auto;
  }
  .bestnews-footer-bottom-content {
      flex-direction: column;
      gap: 15px;
  }
  .bestnews-footer-bottom-links {
       justify-content: center; /* Center bottom links */
  }
  .bestnews-footer-bottom-links li {
      margin: 0 12px; /* Adjust spacing */
  }
}

@media (max-width: 480px) { /* Extra small devices */
   .bestnews-footer-heading { font-size: 1.2rem; }
   .bestnews-footer-main { gap: 30px; }
   .bestnews-social-links { gap: 12px; }
   .bestnews-social-links li a { width: 42px; height: 42px; font-size: 1.1rem;}
   .bestnews-newsletter-form input, .bestnews-newsletter-form button { padding: 12px 15px; font-size: 0.9rem; }
   .bestnews-footer-bottom { font-size: 0.85rem; }
   #dheerdesignfooter{
    margin-right: -66px;
  }
}

