

/* Container Styles */
.contact-drugs-contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch; /* Stretch items to equal height */
    padding: 2rem;
    background-color: #f8f9fa;
    min-height: 100vh;
    box-sizing: border-box; /* Important: Include padding and border in the element's total width and height */
}

/* Form and Map Wrapper Styles */
.contact-drugs-contact-form-wrapper,
.contact-drugs-contact-map-wrapper {
    flex: 1;
    min-width: 300px; /* Ensure a minimum width for small screens */
    max-width: 600px; /* Limit maximum width on large screens */
    padding: 3rem;
    background-color: #fff;
    border-radius: 1.25rem;
    box-shadow: 0 0.625rem 1.875rem rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-bottom: 2.5rem;
    box-sizing: border-box;
}


.contact-drugs-contact-form-wrapper:hover,
.contact-drugs-contact-map-wrapper:hover {
    box-shadow: 0 0.875rem 2.1875rem rgba(0, 0, 0, 0.12);
}


/* Title and Subtitle Styles */
.contact-drugs-contact-title {
    text-align: center;
    margin-bottom: 1.25rem;
    color: #343a40;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 0.0375rem;
}

.contact-drugs-contact-subtitle {
    text-align: center;
    color: #6c757d;
    font-size: 1.125rem;
    margin-bottom: 2.5rem;
    line-height: 1.8;
}

/* Form Styles */
.contact-drugs-contact-form {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.form-drugs-contact-group {
    margin-bottom: 1.875rem;
    box-sizing: border-box;
}

.form-drugs-contact-group label {
    display: block;
    margin-bottom: 0.75rem;
    color: #495057;
    font-weight: 600;
    font-size: 1rem;
    transition: color 0.3s ease;
    box-sizing: border-box;
}

.form-drugs-contact-group:hover label {
    color: #00a3cc;
}

.form-drugs-contact-group input,
.form-drugs-contact-group textarea,
.form-drugs-contact-group select {
    width: 100%;
    padding: 1rem;
    border: 0.125rem solid #ced4da;
    border-radius: 0.625rem;
    background-color: #fff;
    color: #495057;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box;
    height: 3.4375rem; /* Standardized height */
}

.form-drugs-contact-group textarea {
    height: auto;
    resize: vertical;
    min-height: 11.25rem;
}

.form-drugs-contact-group input::placeholder,
.form-drugs-contact-group textarea::placeholder {
    color: #adb5bd;
    font-weight: 400;
}

.form-drugs-contact-group input:focus,
.form-drugs-contact-group textarea:focus,
.form-drugs-contact-group select:focus {
    outline: none;
    border-color: #00a3cc;
    box-shadow: 0 0.25rem 0.625rem rgba(0, 163, 204, 0.15);
}

/* Submit Button Styles */
.button-drugs-contact-submit {
    padding: 1.125rem 2.25rem;
    background-color: #00a3cc;
    color: #fff;
    border: none;
    border-radius: 0.75rem;
    font-size: 1.125rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 0.3125rem 0.75rem rgba(0, 163, 204, 0.2);
    box-sizing: border-box;
}

.button-drugs-contact-submit:hover {
    background-color: #007e99;
    transform: translateY(-0.1875rem);
    box-shadow: 0 0.4375rem 0.9375rem rgba(0, 163, 204, 0.25);
}

.button-drugs-contact-submit:active {
    transform: translateY(-0.0625rem);
    box-shadow: 0 0.1875rem 0.5rem rgba(0, 163, 204, 0.25);
}

/* Map Styles */
.contact-drugs-contact-map-wrapper {
    margin-left: 50px;
}

.map-drugs-contact-placeholder {
    position: relative; /* Required for absolute positioning of iframe */
    overflow: hidden; /* Hide any overflow from the iframe */
    padding-bottom: 75%; /* Aspect ratio for the map (4:3). Adjust as needed. */
    height: 0; /* Set height to 0 to maintain aspect ratio */
}

.map-drugs-contact-placeholder iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    border: 0; /* Remove iframe border if any */
}

/* Media Queries for Responsiveness */
/* Tablets and larger phones */
@media (max-width: 768px) {
    .contact-drugs-contact-container {
        flex-direction: column; /* Stack form and map vertically */
        padding: 1.5rem;
    }

    .contact-drugs-contact-form-wrapper,
    .contact-drugs-contact-map-wrapper {
        width: 95%; /* Take up more width on smaller screens */
        max-width: none; /* Remove max-width limitation */
        margin-bottom: 1.875rem;
        padding: 2.5rem;
    }

    .contact-drugs-contact-title {
        font-size: 1.75rem;
    }

    .contact-drugs-contact-subtitle {
        font-size: 1rem;
        margin-bottom: 2rem;
    }

    .form-drugs-contact-group {
        margin-bottom: 1.5rem;
    }

    .form-drugs-contact-group label {
        font-size: 0.9rem;
    }

    .form-drugs-contact-group input,
    .form-drugs-contact-group textarea,
    .form-drugs-contact-group select {
        padding: 0.875rem;
        font-size: 0.9rem;
        height: 3rem;
    }

    .form-drugs-contact-group textarea {
        min-height: 10rem;
    }

    .button-drugs-contact-submit {
        padding: 1rem 2rem;
        font-size: 1rem;
    }

    .map-drugs-contact-placeholder {
        height: 20rem; /* Adjust map height for tablets */
    }
    /* Map Styles */
.contact-drugs-contact-map-wrapper {
    margin-left: 0px;
}
}

/* Smaller Phones */
@media (max-width: 576px) {
    .contact-drugs-contact-form-wrapper,
    .contact-drugs-contact-map-wrapper {
        padding: 2rem;
    }

    .contact-drugs-contact-title {
        font-size: 1.5rem;
    }

    .contact-drugs-contact-subtitle {
        font-size: 0.9rem;
        margin-bottom: 1.5rem;
    }

    .form-drugs-contact-group {
        margin-bottom: 1.25rem;
    }

    .form-drugs-contact-group label {
        font-size: 0.8rem;
    }

    .form-drugs-contact-group input,
    .form-drugs-contact-group textarea,
    .form-drugs-contact-group select {
        padding: 0.75rem;
        font-size: 0.8rem;
        height: 2.75rem;
    }

    .form-drugs-contact-group textarea {
        min-height: 8rem;
    }

    .button-drugs-contact-submit {
        padding: 0.875rem 1.75rem;
        font-size: 0.9rem;
    }

    .map-drugs-contact-placeholder {
        padding-bottom: 100%; /* Make map full width on very small screens */
    }
    /* Map Styles */
.contact-drugs-contact-map-wrapper {
    margin-left: 0px;
}
}

/* Larger screens */
@media (min-width: 1200px) {
    .contact-drugs-contact-form-wrapper
     {
        max-width: 700px; /* Wider content area on large screens */
        padding: 4rem;
    }
    .contact-drugs-contact-map-wrapper {
        max-width: 600px; /* Wider content area on large screens */
        padding: 2rem;
        height: 700px;
    }

    .contact-drugs-contact-title {
        font-size: 2.5rem;
    }

    .form-drugs-contact-group input,
    .form-drugs-contact-group textarea, 
    .form-drugs-contact-group select {
        font-size: 1.1rem;
        height: 3.75rem;
    }

    .button-drugs-contact-submit {
        font-size: 1.2rem;
    }

    
}