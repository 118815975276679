
  h1 {
    text-align: center;
    color: #333;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    gap: 20px;       /* Adjust spacing between items */

  }
  
  .form-group {
    flex: 1 1 300px;   /* Distribute space evenly, minimum width 300px */
    min-width: 300px;  /* Ensure a minimum width */
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  input[type="text"],
  input[type="tel"],
  input[type="file"],
  select,
  textarea {
    width: 88%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* Important: keeps padding within the width */
    margin-top: 5px;
    font-size: 16px;
  }
  
  textarea {
    resize: vertical;
  }
  
  button {
    background-color: #55a6d4;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0d699e;
  }
  
  .checkbox-group {
    display: flex;
    gap: 20px; /* Adjust the space between checkbox groups */
    align-items: center;
  }
  
  .checkbox-group label {
    display: flex;
    align-items: center;
    gap: 5px; /* Space between checkbox and label text */
    margin-bottom: 0; /* Reset default bottom margin for labels in checkbox groups */
  }
  
  /* Media Query for Responsive Layout */
  @media (max-width: 768px) {
    .container {
      padding: 15px;
      margin: 10px auto;
    }
  
    h1 {
      font-size: 24px;
    }
  
    input[type="text"],
    input[type="tel"],
    input[type="file"],
    select,
    textarea {
      font-size: 14px;
      padding: 8px;
    }
  
    button {
      font-size: 16px;
      padding: 10px 16px;
    }
  
    .checkbox-group {
      flex-direction: column; /* Stack checkboxes on smaller screens */
      align-items: flex-start; /* Align checkboxes to the start */
    }
  
    .checkbox-group label {
      margin-bottom: 10px; /* Add space between stacked checkboxes */
    }
  }
  
  .success-message {
    color: green;
    margin-top: 10px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  #post{
    width: 50%;
  }